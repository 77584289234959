import {
  faFacebookF, faTwitter, faInstagram, faLinkedinIn, faGithub, faGitlab,
} from '@fortawesome/free-brands-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import ImgMen from '../dist/men.svg';
import ImgWomen from '../dist/women.svg';
import UnknownGender from '../dist/unknown-gender.svg';

library.add(
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
  faGithub,
  faGitlab,
);

export const ROUTE_ALIAS = {
  '#Route#CandidateNegativityScreen': 'Negativity',
  '#Route#CandidatePotentialScreen': 'Potensi Influencer',
  '#Route#CandidateTopicScreen': 'Topic',
  '#Route#CandidatePoliticalPartyScreen': 'Political Party Fanaticism',
};
export const SOSMED_TYPE = {
  facebook: faFacebookF,
  twitter: faTwitter,
  instagram: faInstagram,
  linkedin: faLinkedinIn,
};

export const COLOR_SOSMED_TYPE = {
  facebook: '#3B5998',
  twitter: '#1DA1F2',
  instagram: '#BC2A8D',
  linkedin: '#0E76A8',
};
export const OPTION_CHART_BAR_VERTICAL = {
  aspectRatio: 1,
  cornerRadius: 4,
  legend: {
    display: false,
  },
  // scales: {
  //   display: false,
  //   xAxes: [{
  //     type: 'bar',
  //     gridLines: {
  //       display: false,
  //     },
  //   }],
  //   yAxes: [{
  //     type: 'bar',
  //     gridLines: {
  //       display: false,
  //     },
  //   }],
  // },
};
export const OPTION_CHART_BAR_VERTICALTOPIC = {
  aspectRatio: 1,
  cornerRadius: 0,
  legend: {
    display: false,
  },
  // scales: {
  //   display: false,
  //   xAxes: [{
  //     type: 'bar',
  //     gridLines: {
  //       display: false,
  //     },
  //   }],
  //   yAxes: [{
  //     type: 'bar',
  //     gridLines: {
  //       display: false,
  //     },
  //   }],
  // },
};
export const OPTION_CHART_BAR_VERTICALTOPIC_PERSONAL = {
  aspectRatio: 1,
  cornerRadius: 4,
  legend: {
    display: false,
  },
  // scales: {
  //   display: false,
  //   xAxes: [{
  //     type: 'bar',
  //     gridLines: {
  //       display: false,
  //     },
  //   }],
  //   yAxes: [{
  //     type: 'bar',
  //     gridLines: {
  //       display: false,
  //     },
  //   }],
  // },
};
export const OPTION_CHART_LINE_HORIZONTAL = {
  scales: {
    yAxes: [{
      display: true,
      ticks: {
        maxTicksLimit: 12,
        beginAtZero: true,
      },
    }],
    xAxes: [{
      display: true,
      ticks: {
        maxTicksLimit: 12,
      },
    }],
  },
  aspectRatio: 1,
};

export const MAX_PAGINATION = 5;
export const LIMIT_PAGE_PAGINATION = [5, 10, 25, 50, 100];
export const LOTTIE_LOADING = {
  loop: true,
  autoplay: true,
};
export const GENDER = {
  male: ImgMen,
  female: ImgWomen,
  other: UnknownGender,
};

export const PATH = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_PREFIX_URL : '/api';
export const TRACCKINGID = process.env.NODE_ENV === 'development' ? 'UA-221024023-1' : 'UA-221024023-2';
