const statePropertyHistoryCreateBatch = {
};
export default function (state = statePropertyHistoryCreateBatch, action) {
  switch (action.type) {
    case 'PROPERTY_HISTORY_CREATE_BATCH_REDUCER':
      return { ...state, ...action.state };
    default:
      return state;
  }
}
