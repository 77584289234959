const resumeGraphBatchState = {
  isLoading: false,
  error: false,
  result: {},
};
const resumeContentPersonState = {
  isLoading: false,
  error: false,
  result: {},
};
const sumContentPersonState = {
  isLoading: false,
  error: false,
  result: {},
};
const resumeAllContentSosmedByYearPersonState = {
  isLoading: false,
  error: false,
  result: {},
};
const resumeContentSosmedByYearPersonState = {
  isLoading: false,
  error: false,
  result: {},
};
const contentSosmedContentState = {
  isLoading: false,
  error: false,
  result: {},
};
const checkResumeCandidateState = {
  isLoading: false,
  error: false,
  result: false,
};
const dataLinkedinPersonState = {
  isLoading: false,
  error: false,
  result: {},
};
const allSosmedTeninState = {
  isLoading: false,
  error: false,
  result: {
    data: [],
    sosmed: [],
  },
};
const allSosmedInterest = {
  isLoading: false,
  error: false,
  result: {
    data: [],
    sosmed: [],
    graph: {},
  },
};
const allSosmedTeninStateNeg = {
  isLoading: false,
  error: false,
  status: 'all',
  result: {
    data: [],
    allSosmed: [],
    sosmed: [],
    fb: [],
    ig: [],
    tw: [],
  },
};
const wordcloudState = {
  isLoading: false,
  error: false,
  typeSosmed: 'all',
  current: [],
  result: [],
};
const TopInteraksiState = {
  isLoading: false,
  error: false,
  result: {
    instagram: [],
    facebook: [],
    twitter: [],
  },
};
const ResumeChartInterestHome = {
  isLoading: false,
  error: false,
  result: {},
};
const ResumeChartNegativityHome = {
  isLoading: false,
  error: false,
  result: {},
};
const ResumeChartAgeHome = {
  isLoading: false,
  error: false,
  result: [],
};
const ResumeChartGenderHome = {
  isLoading: true,
  error: false,
  result: [],
};
const ResumeTotalProfileBatch = {
  isLoading: false,
  error: false,
  totalProfile: 0,
  totalbatch: 0,
};
export function ResumeTotalProfileBatchReducer(state = ResumeTotalProfileBatch, action) {
  switch (action.type) {
    case 'RESUME_TOTAL_PROFILEBATCH_HOME_REDUCER_PENDING':
      return { ...state, isLoading: true };
    case 'RESUME_TOTAL_PROFILE_HOME_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        totalProfile: action.totalProfile,
      };
    case 'RESUME_TOTAL_BATCH_HOME_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        totalbatch: action.totalbatch,
      };
    case 'RESUME_TOTAL_PROFILEBATCH_HOME_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function ResumeChartGenderHomeReducer(state = ResumeChartGenderHome, action) {
  switch (action.type) {
    case 'RESUME_CHART_GENDER_HOME_REDUCER_PENDING':
      return { ...state, isLoading: true };
    case 'RESUME_CHART_GENDER_HOME_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'RESUME_CHART_GENDER_HOME_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function ResumeChartAgeHomeReducer(state = ResumeChartAgeHome, action) {
  switch (action.type) {
    case 'RESUME_CHART_AGE_HOME_REDUCER_PENDING':
      return { ...state, isLoading: true };
    case 'RESUME_CHART_AGE_HOME_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'RESUME_CHART_AGE_HOME_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function ResumeChartNegativityHomeReducer(state = ResumeChartNegativityHome, action) {
  switch (action.type) {
    case 'RESUME_CHART_NEGATIVITY_HOME_REDUCER_PENDING':
      return { ...state, isLoading: true };
    case 'RESUME_CHART_NEGATIVITY_HOME_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'RESUME_CHART_NEGATIVITY_HOME_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function ResumeChartInterestHomeReducer(state = ResumeChartInterestHome, action) {
  switch (action.type) {
    case 'RESUME_CHART_INTEREST_HOME_REDUCER_PENDING':
      return { ...state, isLoading: true };
    case 'RESUME_CHART_INTEREST_HOME_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'RESUME_CHART_INTEREST_HOME_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function AllSosmedNegativityReducer(
  state = allSosmedTeninStateNeg,
  action,
) {
  switch (action.type) {
    case 'ALL_SOSMED_NEGATIVITY_REDUCER_PENDING':
      return { ...state, isLoading: true, result: { ...allSosmedTeninStateNeg.result } };
    case 'ALL_SOSMED_NEGATIVITY_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        result: action.payload,
      };
    case 'ALL_SOSMED_NEGATIVITY_REDUCER_REJECTED':
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case 'ALL_SOSMED_NEGATIVITY_CHANGE_TYPE':
      return {
        ...state,
        status: action.status,
      };
    default:
      return state;
  }
}
export function AllSosmedInterestReducer(
  state = allSosmedInterest,
  action,
) {
  switch (action.type) {
    case 'ALL_SOSMED_INTEREST_REDUCER_PENDING':
      return { ...state, isLoading: true, result: { ...allSosmedInterest.result } };
    case 'ALL_SOSMED_INTEREST_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        result: action.payload,
      };
    case 'ALL_SOSMED_INTEREST_REDUCER_REJECTED':
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    default:
      return state;
  }
}

export function AllSosmedPoliticalPartyReducer(
  state = allSosmedTeninState,
  action,
) {
  switch (action.type) {
    case 'ALL_SOSMED_POLITICALPARTY_REDUCER_PENDING':
      return { ...state, isLoading: true, result: { ...allSosmedTeninState.result } };
    case 'ALL_SOSMED_POLITICALPARTY_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        result: action.payload,
      };
    case 'ALL_SOSMED_POLITICALPARTY_REDUCER_REJECTED':
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    default:
      return state;
  }
}
export function CheckResumeCandidatePotentialReducer(
  state = checkResumeCandidateState,
  action,
) {
  switch (action.type) {
    case 'CHECK_RESUME_CANDIDATE_POTENTIAL_REDUCER_PENDING':
      return { ...state, isLoading: true, result: false };
    case 'CHECK_RESUME_CANDIDATE_POTENTIAL_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        result: true,
      };
    case 'CHECK_RESUME_CANDIDATE_POTENTIAL_REDUCER_REJECTED':
      return {
        ...state,
        isLoading: false,
        error: true,
        result: false,
      };
    default:
      return state;
  }
}
export function CheckResumeCandidateTendencyReducer(
  state = checkResumeCandidateState,
  action,
) {
  switch (action.type) {
    case 'CHECK_RESUME_CANDIDATE_TENDENCY_REDUCER_PENDING':
      return { ...state, isLoading: true, result: false };
    case 'CHECK_RESUME_CANDIDATE_TENDENCY_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        result: true,
      };
    case 'CHECK_RESUME_CANDIDATE_TENDENCY_REDUCER_REJECTED':
      return {
        ...state,
        isLoading: false,
        error: true,
        result: false,
      };
    default:
      return state;
  }
}
export function CheckResumeCandidateInterestReducer(
  state = checkResumeCandidateState,
  action,
) {
  switch (action.type) {
    case 'CHECK_RESUME_CANDIDATE_INTEREST_REDUCER_PENDING':
      return { ...state, isLoading: true, result: false };
    case 'CHECK_RESUME_CANDIDATE_INTEREST_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        result: true,
      };
    case 'CHECK_RESUME_CANDIDATE_INTEREST_REDUCER_REJECTED':
      return {
        ...state,
        isLoading: false,
        error: true,
        result: false,
      };
    default:
      return state;
  }
}
export function CheckResumeCandidatePoliticalPartyReducer(
  state = checkResumeCandidateState,
  action,
) {
  switch (action.type) {
    case 'CHECK_RESUME_CANDIDATE_POLITICAL_PARTY_REDUCER_PENDING':
      return { ...state, isLoading: true, result: false };
    case 'CHECK_RESUME_CANDIDATE_POLITICAL_PARTY_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        result: true,
      };
    case 'CHECK_RESUME_CANDIDATE_POLITICAL_PARTY_REDUCER_REJECTED':
      return {
        ...state,
        isLoading: false,
        error: true,
        result: false,
      };
    default:
      return state;
  }
}
export function ResumeGraphBatchTendecyReducer(state = resumeGraphBatchState, action) {
  switch (action.type) {
    case 'RESUME_GRAPH_BATCH_TENDENCY_REDUCER_PENDING':
      return { ...state, isLoading: true };
    case 'RESUME_GRAPH_BATCH_TENDENCY_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'RESUME_GRAPH_BATCH_TENDENCY_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function ResumeGraphBatchInterestReducer(state = resumeGraphBatchState, action) {
  switch (action.type) {
    case 'RESUME_GRAPH_BATCH_INTEREST_REDUCER_PENDING':
      return { ...state, isLoading: true };
    case 'RESUME_GRAPH_BATCH_INTEREST_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'RESUME_GRAPH_BATCH_INTEREST_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function ResumeGraphBatchPoliticalPartyReducer(state = resumeGraphBatchState, action) {
  switch (action.type) {
    case 'RESUME_GRAPH_BATCH_POLITICAL_PARTY_REDUCER_PENDING':
      return { ...state, isLoading: true };
    case 'RESUME_GRAPH_BATCH_POLITICAL_PARTY_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'RESUME_GRAPH_BATCH_POLITICAL_PARTY_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function ResumeContentPersonReducer(state = resumeContentPersonState, action) {
  switch (action.type) {
    case 'RESUME_CONTENT_PERSON_REDUCER_PENDING':
      return { ...state, isLoading: true };
    case 'RESUME_CONTENT_PERSON_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'RESUME_CONTENT_PERSON_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function SumContentPersonReducer(state = sumContentPersonState, action) {
  switch (action.type) {
    case 'SUM_CONTENT_PERSON_REDUCER_PENDING':
      return { ...state, isLoading: true };
    case 'SUM_CONTENT_PERSON_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'SUM_CONTENT_PERSON_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function ResumeAllContentSosmedByYearPersonReducer(
  state = resumeAllContentSosmedByYearPersonState,
  action,
) {
  switch (action.type) {
    case 'RESUME_ALL_CONTENT_SOSMED_BY_YEAR_PERSON_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'RESUME_ALL_CONTENT_SOSMED_BY_YEAR_PERSON_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'RESUME_ALL_CONTENT_SOSMED_BY_YEAR_PERSON_SET':
      // eslint-disable-next-line no-case-declarations
      const { year, ...rest } = action.payload;
      return {
        ...state,
        result: {
          ...state.result,
          [year]: rest,
        },
      };
    case 'RESUME_ALL_CONTENT_SOSMED_BY_YEAR_PERSON_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function ResumeFacebookContentSosmedByYearPersonReducer(
  state = resumeContentSosmedByYearPersonState,
  action,
) {
  switch (action.type) {
    case 'RESUME_FACEBOOK_CONTENT_SOSMED_BY_YEAR_PERSON_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'RESUME_FACEBOOK_CONTENT_SOSMED_BY_YEAR_PERSON_SET':
      // eslint-disable-next-line no-case-declarations
      const { year, ...rest } = action.payload;
      return {
        ...state,
        result: {
          ...state.result,
          [year]: rest,
        },
      };
    case 'RESUME_FACEBOOK_CONTENT_SOSMED_BY_YEAR_PERSON_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'RESUME_FACEBOOK_CONTENT_SOSMED_BY_YEAR_PERSON_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function ResumeInstagramContentSosmedByYearPersonReducer(
  state = resumeContentSosmedByYearPersonState,
  action,
) {
  switch (action.type) {
    case 'RESUME_INSTAGRAM_CONTENT_SOSMED_BY_YEAR_PERSON_PENDING':
      return { ...state, isLoading: true };
    case 'RESUME_INSTAGRAM_CONTENT_SOSMED_BY_YEAR_PERSON_SET':
      // eslint-disable-next-line no-case-declarations
      const { year, ...rest } = action.payload;
      return {
        ...state,
        result: {
          ...state.result,
          [year]: rest,
        },
      };
    case 'RESUME_INSTAGRAM_CONTENT_SOSMED_BY_YEAR_PERSON_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'RESUME_INSTAGRAM_CONTENT_SOSMED_BY_YEAR_PERSON_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function ResumeTwitterContentSosmedByYearPersonReducer(
  state = resumeContentSosmedByYearPersonState,
  action,
) {
  switch (action.type) {
    case 'RESUME_TWITTER_CONTENT_SOSMED_BY_YEAR_PERSON_PENDING':
      return { ...state, isLoading: true };
    case 'RESUME_TWITTER_CONTENT_SOSMED_BY_YEAR_PERSON_SET':
      // eslint-disable-next-line no-case-declarations
      const { year, ...rest } = action.payload;
      return {
        ...state,
        result: {
          ...state.result,
          [year]: rest,
        },
      };
    case 'RESUME_TWITTER_CONTENT_SOSMED_BY_YEAR_PERSON_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'RESUME_TWITTER_CONTENT_SOSMED_BY_YEAR_PERSON_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function ResumeLinkedinContentSosmedByYearPersonReducer(
  state = resumeContentSosmedByYearPersonState,
  action,
) {
  switch (action.type) {
    case 'RESUME_LINKEDIN_CONTENT_SOSMED_BY_YEAR_PERSON_PENDING':
      return { ...state, isLoading: true };
    case 'RESUME_LINKEDIN_CONTENT_SOSMED_BY_YEAR_PERSON_FULFILLED':
      // eslint-disable-next-line no-case-declarations
      const { year, ...rest } = action.payload;
      return {
        ...state,
        isLoading: false,
        result: {
          [year]: rest,
        },
      };
    case 'RESUME_LINKEDIN_CONTENT_SOSMED_BY_YEAR_PERSON_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function ContentSosmedContentReducer(state = contentSosmedContentState, action) {
  switch (action.type) {
    case 'CONTENT_SOSMED_CONTENT_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'CONTENT_SOSMED_CONTENT_REDUCER_FULFILLED':
      return { ...state, isLoading: false };
    case 'CONTENT_SOSMED_CONTENT_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    case 'CONTENT_SOSMED_CONTENT_REDUCER_SET_DATA':
      return {
        ...state,
        result: {
          ...state.result,
          [action.payload.sosmed]: action.payload.data,
        },
      };
    default:
      return state;
  }
}
export function DataLinkedinPersonReducer(state = dataLinkedinPersonState, action) {
  switch (action.type) {
    case 'DATA_LINKEDIN_PERSON_REDUCER_PENDING':
      return { ...state, isLoading: true };
    case 'DATA_LINKEDIN_PERSON_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'DATA_LINKEDIN_PERSON_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    case 'DATA_LINKEDIN_PERSON_REDUCER_RESET':
      return dataLinkedinPersonState;
    default:
      return state;
  }
}
export function WordCloudReducer(state = wordcloudState, action) {
  switch (action.type) {
    case 'WORDCLOUD_REDUCER_PENDING':
      return { ...state, isLoading: true };
    case 'WORDCLOUD_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        result: action.payload,
        typeSosmed: 'all',
        current: action.current,
      };
    case 'WORDCLOUD_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    case 'WORDCLOUD_REDUCER_CHANGE_TYPE':
      return {
        ...state,
        typeSosmed: action.typeSosmed,
      };
    default:
      return state;
  }
}
export function SosmedTopInteraksiReducer(state = TopInteraksiState, action) {
  switch (action.type) {
    case 'TOP_INTERAKSI_REDUCER_PENDING':
      return { ...state, isLoading: true };
    case 'TOP_INTERAKSI_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'TOP_INTERAKSI_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    case 'TOP_INTERAKSI_REDUCER_RESET':
      return TopInteraksiState;
    default:
      return state;
  }
}
