import AXIOS_BASE from '../../config';
import { PATH } from '../../variables';

const action = {};

action.listNotif = function () {
  return AXIOS_BASE.NOTIF.get(`${PATH}/notification/list?V_RANGE_DAY=30`);
};
action.listNotifAll = function (LIMIT) {
  return AXIOS_BASE.NOTIF.get(`${PATH}/notification/list?LIMIT=${LIMIT}`);
};
action.clickNotif = function () {
  return AXIOS_BASE.NOTIF.get(`${PATH}/notification/click`);
};
action.readNotif = function () {
  return AXIOS_BASE.NOTIF.get(`${PATH}/notification/read`);
};
action.detailNotif = function () {
  return AXIOS_BASE.NOTIF.get(`${PATH}/notification/detail-data`);
};

export default action;
