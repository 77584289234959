/* eslint-disable max-len */
import AXIOS_BASE from '../../config';
import { PATH } from '../../variables';

const action = {};

action.lastActivityDatePersonal = function (params) {
  return AXIOS_BASE.RESUME.get(
    `${`${PATH}/resume/personal/last-activity-date`
    + `?C_PERSONAL_ID=${params.personal_id}`}${
      params.sosmed}` ? `&C_SOCMED_ID=${params.sosmed}` : '',
  );
};
action.donwloadZipResume = function (batchId) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/batch/zip-batch?C_BATCH_ID=${batchId}`,
  );
};
action.donwloadZipResume = function (batchId) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/batch/zip-batch?C_BATCH_ID=${batchId}`,
  );
};
action.totalSentiment = function (personid) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/total/sentiment`
    + `?C_PERSONAL_ID=${personid}`,
  );
};
action.resumeChartInterest = function (query) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/batch/chart-interest`
    + `?${query}`,
  );
};
action.resumeChartNegativity = function (query) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/batch/chart-negativity`
    + `?${query}`,
  );
};
action.resumeChartPoliticalParty = function (query) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/batch/chart-political-party`
    + `?${query}`,
  );
};
action.totalConnected = function (params) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/batch/`
    + `${params.sosmed}-total-connected`
    + `?C_BATCH_ID=${params.batchid}`,
  );
};
action.countUserValid = function (batchid) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/batch/count-valid-account?C_BATCH_ID=${batchid}`,
  );
};
action.topSkill = function () {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/batch/top-skill`,
  );
};
action.mostNegativityList = function (query, config = {}) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/batch/list-most-negative`
    + `?${query}`,
    config,
  );
};
action.mostInfluenceList = function (query, config = {}) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/batch/list-most-influence`
    + `?${query}`,
    config,
  );
};
action.mostInterestList = function (query, config = {}) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/batch/list-most-interest`
    + `?${query}`,
    config,
  );
};
action.mostPoliticalPartyList = function (query, config = {}) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/batch/list-most-political-party`
    + `?${query}`,
    config,
  );
};
action.createPdf = function (personalId) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/personal/create-pdf?C_PERSONAL_ID=${personalId}`,
  );
};
action.resumeLinkedinProfile = function (personalId) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/personal/linkedin-profile?C_PERSONAL_ID=${personalId}`,
  );
};
action.totalContent = function (personalId) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/total/content?C_PERSONAL_ID=${personalId}`,
  );
};
action.totalDataContent = function (query) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/total/content?${query}`,
  );
};
action.activitySosmed = function (query) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/personal/all-activity?${query}`,
  );
};
action.lastActivity = function (personalId) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/personal/last-activity-date?C_PERSONAL_ID=${personalId}`,
  );
};
action.allContent = function (query, config) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/personal/all-content?${query}`,
    config,
  );
};
action.resumeInterestNegativity = function (personalId) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/personal/resume-interest-negativity-politicalparty-value?C_PERSONAL_ID=${personalId}`,
  );
};
action.topPostNegativitySosmed = function (query) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/personal/top-post-negativity-allsosmed?${query}`,
  );
};
action.topPostInterestSosmed = function (query) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/personal/top-post-interest-allsosmed?${query}`,
  );
};
action.allContentSosmed = function (query, config) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/personal/all-content?${query}`,
    config,
  );
};
action.topInterestBySosmed = function (query) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/personal/top-post-interest-by-sosmed
    ?${query}`,
  );
};
action.topNegativityBySosmed = function (query) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/personal/top-post-negativity-by-sosmed
    ?${query}`,
  );
};
action.topNegativity = function (query) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/personal/top-post-negativity
    ?${query}`,
  );
};
action.topInterest = function (query) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/personal/top-post-interest
    ?${query}`,
  );
};
action.wordCloud = function (C_PERSONAL_ID) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/personal/list-wordcount-and-sosmed?C_PERSONAL_ID=${C_PERSONAL_ID}`,
  );
};
action.resumeClientChartInterestCompany = function (C_CLIENT_ID) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/client/chart-interest?C_CLIENT_ID=${C_CLIENT_ID}`,
  );
};
action.resumeClientChartNegativityCompany = function (C_CLIENT_ID) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/client/chart-negativity?C_CLIENT_ID=${C_CLIENT_ID}`,
  );
};
action.resumeClientChartAgeCompany = function (C_CLIENT_ID) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/client/chart-age?C_CLIENT_ID=${C_CLIENT_ID}`,
  );
};
action.resumeClientChartGenderCompany = function (C_CLIENT_ID) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/client/chart-gender?C_CLIENT_ID=${C_CLIENT_ID}`,
  );
};
action.resumeClientTotalProfileCompany = function (C_CLIENT_ID) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/client/total-person?C_CLIENT_ID=${C_CLIENT_ID}`,
  );
};
action.totalbatchCompany = function (C_CLIENT_ID) {
  return AXIOS_BASE.BATCH.get(
    `${PATH}/batch/list-count?C_CLIENT_ID=${C_CLIENT_ID}`,
  );
};
action.downloadExcelBatch = function (batchId) {
  // return new Promise((resolve, reject) => {
  //   AXIOS_BASE.RESUME.get(
  //     `${PATH}/resume/batch/export-to-excel?C_BATCH_ID=${batchId}`,
  //     {
  //       headers: {
  //         'user-agent': 'Mozilla/4.0 MDN Example',
  //         'content-type': 'application/vnd.ms-excel;charset=UTF-8',
  //       },
  //       responseType: 'blob',
  //     },
  //   ).then((res) => {
  //     let filename = decodeURI(res.headers.get('Content-Disposition').split('filename=')[1]);// 获取后端headers里面的文件名
  //     filename = filename.split('"').join('');
  //     const url = window.URL.createObjectURL(new Blob([res.data]));
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', filename);
  //     document.body.appendChild(link);
  //     link.click();
  //   })
  //     .catch((err) => {
  //       reject(err);
  //     });
  // });
  const localStorage = JSON.parse(window.localStorage['persist:root']);
  const status = JSON.parse(localStorage.StatusPageReducer);
  let url = `${PATH}/resume/batch/export-to-excel?C_BATCH_ID=${batchId}`;
  if (process.env.NODE_ENV === 'development') {
    url = `http://${process.env.REACT_APP_URL_RESUME}${PATH}/resume/batch/export-to-excel?C_BATCH_ID=${batchId}`;
  }
  return new Promise((resolve) => {
    fetch(url, {
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin',
      headers: {
        'user-agent': 'Mozilla/4.0 MDN Example',
        'content-type': 'application/vnd.ms-excel;charset=UTF-8',
        'Access-Control-Expose-Headers': 'Content-Disposition',
        Authorization: status.token,
      },
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
    })
      .then((res) => res.blob().then((blob) => {
        let filename = decodeURI(res.headers.get('Content-Disposition').split('filename=')[1]);// 获取后端headers里面的文件名
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, filename); // 兼容ie10
        } else {
          filename = filename.split('"').join('');
          const a = document.createElement('a');
          document.body.appendChild(a); // 兼容火狐，将a标签添加到body当中
          a.href = window.URL.createObjectURL(blob);
          a.download = `${filename}`;
          a.target = '_blank'; // a标签增加target属性
          a.click();
          a.remove();// 移除a标签
          window.URL.revokeObjectURL(url);
        }
        resolve();
      }));
  });
};
export default action;
