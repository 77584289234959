import AXIOS_BASE from '../../config';
import { PATH } from '../../variables';

const action = {};

action.dataPersonalInstagram = function (sosmedId) {
  return AXIOS_BASE.INSTAGRAM.get(
    `${PATH}/instagram/general`
    + `?C_SOSMED_PERSONAL_ID=${sosmedId}`,
  );
};
action.dataSosmedUrlInstagram = function (sosmedId) {
  return AXIOS_BASE.INSTAGRAM.get(
    `${PATH}/instagram/sosmed-url`
    + `?C_SOSMED_PERSONAL_ID=${sosmedId}`,
  );
};
action.lastActivityDateInstagram = function (sosmedId) {
  return AXIOS_BASE.INSTAGRAM.get(
    `${PATH}/instagram/last-activity-date`
    + `?C_SOSMED_PERSONAL_ID=${sosmedId}`,
  );
};
action.totalContentInstagram = function (sosmedId) {
  return AXIOS_BASE.INSTAGRAM.get(
    `${PATH}/instagram/posts-count`
    + `?C_SOSMED_PERSONAL_ID=${sosmedId}`,
  );
};
action.totalSentimentInstagram = function (sosmedId) {
  return AXIOS_BASE.INSTAGRAM.get(
    `${PATH}/instagram/total-sentiment`
    + `?C_SOSMED_PERSONAL_ID=${sosmedId}`,
  );
};
action.topInterestInstagram = function (query) {
  return AXIOS_BASE.INSTAGRAM.get(
    `${PATH}/instagram/top-interest`
    + `?${query}`,
  );
};
action.topTendencyInstagram = function (query) {
  return AXIOS_BASE.INSTAGRAM.get(
    `${PATH}/instagram/top-tendency`
    + `?${query}`,
  );
};
action.topPoliticalpartyInstagram = function (query) {
  return AXIOS_BASE.INSTAGRAM.get(
    `${PATH}/instagram/top-politicalparty`
    + `?${query}`,
  );
};
action.engagementRateInstagram = function (sosmedId) {
  return AXIOS_BASE.INSTAGRAM.get(
    `${PATH}/instagram/engagement-rate`
    + `?C_SOSMED_PERSONAL_ID=${sosmedId}`,
  );
};
action.postSosmedInstagram = function (query, config) {
  return AXIOS_BASE.INSTAGRAM.get(
    `${PATH}/instagram/posts`
    + `?${query}`,
    config,
  );
};
action.postSosmedCommentInstagram = function (query) {
  return AXIOS_BASE.INSTAGRAM.get(
    `${PATH}/instagram/posts/comments`
    + `?${query}`,
  );
};
action.activityPersonalInstagram = function (query) {
  return AXIOS_BASE.INSTAGRAM.get(
    `${PATH}/instagram/activity`
    + `?${query}`,
  );
};
export default action;
