const descriptionPersonState = {
  isLoading: false,
  result: {
    sosmed: {},
    topic: [],
    tendency: [],
  },
  error: false,
};
export default function (state = descriptionPersonState, action) {
  switch (action.type) {
    case 'DESCRIPTION_PERSON_REDUCER_PENDING':
      return { ...state, isLoading: true };
    case 'DESCRIPTION_PERSON_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'DESCRIPTION_PERSON_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
