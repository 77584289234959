import AXIOS_BASE from '../../config';
import { PATH } from '../../variables';

const action = {};

action.transactionListPackage = function () {
  return AXIOS_BASE.PAYMENT.get(
    `${PATH}/transaction/list-package`,
  );
};
action.transactionPayment = function () {
  return AXIOS_BASE.PAYMENT.get(
    `${PATH}/transaction/list-payment`,
  );
};
action.transactionHistory = function (query = '') {
  return AXIOS_BASE.PAYMENT.get(
    `${PATH}/transaction/list-transaction?${query}`,
  );
};
action.transactionHistoryItem = function (C_ORDER_ID) {
  return AXIOS_BASE.PAYMENT.get(
    `${PATH}/transaction/list-transaction-item?C_ORDER_ID=${C_ORDER_ID}`,
  );
};
action.transactionCart = function (config = {}) {
  return AXIOS_BASE.PAYMENT.get(
    `${PATH}/transaction/list-cart`,
    config,
  );
};
action.transactionCheck = function () {
  return AXIOS_BASE.PAYMENT.get(
    `${PATH}/transaction/check-profile`,
  );
};
action.transactionUploadPayment = function (params) {
  const formData = new FormData();
  formData.append('FILES', params.FILES);
  formData.set('C_ORDER_ID', params.orderId);
  return AXIOS_BASE.PAYMENT.post(
    `${PATH}/transaction/upload-payment`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
};
action.initCart = function (V_COUNT = 0) {
  return AXIOS_BASE.PAYMENT.post(
    `${PATH}/transaction/init-cart`,
    {
      V_COUNT,
    },
  );
};
action.decreCart = function (LIST_ORDER = []) {
  return AXIOS_BASE.PAYMENT.post(
    `${PATH}/transaction/decrement-cart`,
    {
      LIST_ORDER,
    },
  );
};
action.initOrder = function (C_DRAFT_ID, PRODUCT_CODE) {
  return AXIOS_BASE.PAYMENT.post(
    `${PATH}/transaction/init-order`,
    {
      C_DRAFT_ID,
      PRODUCT_CODE,
    },
  );
};
action.claimPromo = function (V_PROMO_CODE) {
  return AXIOS_BASE.PAYMENT.post(
    `${PATH}/transaction/apply-promo-code`,
    {
      V_PROMO_CODE,
    },
  );
};
action.deletePromo = function () {
  return AXIOS_BASE.PAYMENT.post(
    `${PATH}/transaction/remove-promo-code`,
  );
};
action.exportInvoice = function (C_INVOICE_ID) {
  return AXIOS_BASE.PAYMENT.get(
    `${PATH}/transaction/export-invoice?C_INVOICE_ID=${C_INVOICE_ID}`,
  );
};
action.exportTaxInvoice = function (C_INVOICE_ID) {
  return AXIOS_BASE.PAYMENT.get(
    `${PATH}/transaction/export-tax-invoice?C_INVOICE_ID=${C_INVOICE_ID}`,
  );
};
action.checkExpiredTransaction = function () {
  return AXIOS_BASE.PAYMENT.get(
    `${PATH}/transaction/check-expired-transaction`,
  );
};
action.draftBiling = function (V_PRODUCT_CODE, V_GATEWAY, V_PRODUCT_TYPE) {
  return AXIOS_BASE.PAYMENT.post(
    `${PATH}/transaction/draft-billing`,
    {
      V_PRODUCT_CODE,
      V_GATEWAY,
      V_PRODUCT_TYPE,
    },
  );
};
action.callbackPayment = function (orderId, invoiceId, productCode) {
  return AXIOS_BASE.PAYMENT.get(`${PATH}/transaction/back?orderId=${orderId}&invoiceId=${invoiceId}&productCode=${productCode}`);
};
export default action;
