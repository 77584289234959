const resendState = {
  active: false,
  email: '',
  page: {
    register: {
      times: 0,
      rehit: false,
    },
    forgotPassword: {
      times: 0,
      rehit: false,
    },
  },
};
export default function ResendOtpTypeReducer(state = resendState, action) {
  switch (action.type) {
    case 'SET_RESEND_OTP_STATUS_REDUCER':
      return {
        ...state,
        active: action.payload.active,
        email: action.payload.email,
        page: {
          ...state.page,
          [action.payload.label]: action.payload.value,
        },
      };
    default:
      return state;
  }
}
