const totalActivityState = {
  isLoading: false,
  error: false,
  result: {},
};
const activityPersonSosmedState = {
  isLoading: false,
  error: false,
  result: {},
};
export function ActivityPersonFacebookReducer(state = activityPersonSosmedState, action) {
  switch (action.type) {
    case 'ACTIVITY_PERSON_FACEBOOK_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'ACTIVITY_PERSON_FACEBOOK_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'ACTIVITY_PERSON_FACEBOOK_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function ActivityPersonInstagramReducer(state = activityPersonSosmedState, action) {
  switch (action.type) {
    case 'ACTIVITY_PERSON_INSTAGRAM_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'ACTIVITY_PERSON_INSTAGRAM_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'ACTIVITY_PERSON_INSTAGRAM_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function ActivityPersonTwitterReducer(state = activityPersonSosmedState, action) {
  switch (action.type) {
    case 'ACTIVITY_PERSON_TWITTER_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'ACTIVITY_PERSON_TWITTER_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'ACTIVITY_PERSON_TWITTER_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function TotalActivityPersonReducer(state = totalActivityState, action) {
  switch (action.type) {
    case 'TOTAL_ACTIVITY_PERSON_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'TOTAL_ACTIVITY_PERSON_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'TOTAL_ACTIVITY_PERSON_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export const a = null;
