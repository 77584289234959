export const SIGN_EMAIL_CLIENT_ID = 'SIGN_EMAIL_CLIENT_ID';

const initialState = {
  C_CLIENT_ID: '',
  V_WORK_EMAIL: '',
};

export default function messageReducer(state = initialState, action) {
  switch (action.type) {
    case SIGN_EMAIL_CLIENT_ID:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
