const notificationState = {};

function NotificationReducer(state = notificationState, action) {
  const sStatic = {};
  switch (action.type) {
    case 'ADD_NOTIF_APPLIACTION':
      return { ...state, [action.payload]: true };
    case 'REMOVE_NOTIF_APPLICATION':
      sStatic[action.payload] = undefined;
      return { ...state, ...sStatic };
    default:
      return state;
  }
}

export default NotificationReducer;
