export const MESSAGE_ERROR = {
  System: 'Error Sistem.',
  NotMatch: (field) => `${field} Tidak Sama`,
  NotConnection: 'Tidak ada koneksi.',
  RequiredLogin: 'Email atau Password harus diisi terlebih dahulu.',
  Required: 'Harus diisi terlebih dahulu.',
  RequiredSosmed: 'Harus diisi terlebih dahulu salah satu sosmed.',
  FormatNotValid: 'Format tidak sesuai.',
  user_not_foundAuth: 'User tidak terdaftar.',
  invalid_secretAuth: 'Kata Sandi yang Anda masukkan salah.',
  invalid_dob: 'Format tanggal lahir salah, harap sesuaikan format yang benar contoh: 23-1-1945',
  invalid_format: 'Format tidak sesuai, harap sesuaikan',
  invalid_email: 'Harap masukan email kandidat sebagai unique id dari setiap kandidat',
  invalid_token: 'akun tidak terdaftar',
  account_not_found: 'akun tidak ditemukan',
  invalid_expired_link: 'link verifikasi sudah tidak berlaku',
  invalid_parameterAuth: 'Email atau password Anda tidak ditemukan. Silakan isi data terlebih dahulu',
  somelse: 'Proses gagal, harap hubungi admin bila membutuhkan bantuan.',
  invalid_authAuth: 'Email Atau Password yang Anda masukkan salah',
  cooldown_loginAuth: 'Terlalu banyak percobaan login pada satu waktu silakan coba dalam berberapa saat lagi',
  inactive_accountAuth: 'Akun Anda belum aktif',
  500: 'Server Sibuk. tunggu beberapa saat lagi. Error Code (500)',
  504: 'Server Sibuk. tunggu beberapa saat lagi. Error Code (504)',
  400: 'Terjadi Kesalahan, silakan hubungi admin. Error Code (400)',
  401: 'Terjadi Kesalahan, silakan hubungi admin. Error Code (401)',
  404: 'Terjadi Kesalahan, silakan hubungi admin. Error Code (404)',
  1000: 'Terjadi Kesalahan, silakan hubungi admin.',
};
export const MESSAGE_DIALOG = {
  LogoutDialog: {
    title: 'Konfirmasi Keluar',
    content: 'Anda yakin ingin keluar dari halaman ini?',
  },
  ToHomeDialog: {
    title: 'Kembali ke Beranda?',
    content: 'Jika Anda tidak sengaja klik tombol Beranda, cukup klik Tetap di Halaman Ini.',
  },
  SubmitValidationTemp: {
    title: 'Apakah Anda yakin ingin melakukan perubahan?',
    // content: 'Jika Anda tidak sengaja klik tombol Beranda, cukup klik Tetap di Halaman Ini.',
  },
  SubmitValidation_update_batch: {
    title: 'Submit Sekarang?',
    content: 'Apakah Anda sudah yakin untuk menambah profil baru dengan daftar kandidat yang telah di masukan?',
  },
  SubmitValidation_0_update_batch: {
    title: 'Submit Sekarang?',
    content: 'Apakah Anda sudah yakin untuk menambah profil baru dengan daftar kandidat yang telah di masukan?',
  },
  SubmitValidation_1_update_batch: {
    title: 'Data tidak sesuai?',
    content: 'Terdapat data yang tidak sesuai, harap cek kembali data kandidat Anda !',
  },
  SubmitValidation_2_update_batch: {
    title: 'Yakin Submit Sekarang?',
    content: 'Terdapat kandidat dengan data yang kosong, apakah Anda yakin akan submit data sekarang ?',
  },
  SubmitValidation_0_new_batch: {
    title: 'Submit Sekarang?',
    content: 'Apakah Anda sudah yakin untuk membuat batch baru dengan daftar kandidat yang telah di masukan?',
  },
  SubmitValidation_1_new_batch: {
    title: 'Data tidak sesuai?',
    content: 'Terdapat data yang tidak sesuai, harap cek kembali data kandidat Anda !',
  },
  SubmitValidation_2_new_batch: {
    title: 'Yakin Submit Sekarang?',
    content: 'Terdapat kandidat dengan data yang kosong, apakah Anda yakin akan submit data sekarang ?',
  },
  SubmitValidation_0_false: {
    title: 'Submit Sekarang?',
    content: 'Apakah Anda sudah yakin untuk menambah profil baru dengan daftar kandidat yang telah di masukan?',
  },
  SubmitValidation_1_false: {
    title: 'Data tidak sesuai?',
    content: 'Terdapat data yang tidak sesuai, harap cek kembali data kandidat Anda !',
  },
  SubmitValidation_2_false: {
    title: 'Yakin Submit Sekarang?',
    content: 'Terdapat kandidat dengan data yang kosong, apakah Anda yakin akan submit data sekarang ?',
  },
  DeleteValidation: {
    title: 'Apakah Anda ingin menghapus?',
    content: '...',
  },
  ConfirmReupload: {
    title: 'Apakah Anda yakin ingin melakukan Unggah Ulang?',
    content: 'Dengan melakukan unggah ulang, anda akan menghapus data yang sudah terunggah sebelumnya.',
  },
};
