const masterState = {
  isLoading: false,
  error: false,
  result: {
    data: [],
  },
};
const masterGradeState = {
  isLoading: false,
  error: false,
  result: {
    status: 200,
    success: true,
    msg: 'ok',
    data: [
      {
        C_VALUE_ID: 'nano-influencer',
        V_MASTER: 'influenceCategory',
        V_VALUE: 'Nano Influencer',
        V_DESCRIPTION: null,
      },
      {
        C_VALUE_ID: 'micro-influencer',
        V_MASTER: 'influenceCategory',
        V_VALUE: 'Micro Influencer',
        V_DESCRIPTION: null,
      },
      {
        C_VALUE_ID: 'regular-influencer',
        V_MASTER: 'influenceCategory',
        V_VALUE: 'Regular Influencer',
        V_DESCRIPTION: null,
      },
      {
        C_VALUE_ID: 'rising-influencer',
        V_MASTER: 'influenceCategory',
        V_VALUE: 'Rising Influencer',
        V_DESCRIPTION: null,
      },
      {
        C_VALUE_ID: 'mid-influencer',
        V_MASTER: 'influenceCategory',
        V_VALUE: 'Mid Influencer',
        V_DESCRIPTION: null,
      },
      {
        C_VALUE_ID: 'macro-influencer',
        V_MASTER: 'influenceCategory',
        V_VALUE: 'Macro Influencer',
        V_DESCRIPTION: null,
      },
      {
        C_VALUE_ID: 'mega-influencer',
        V_MASTER: 'influenceCategory',
        V_VALUE: 'Mega Influencer',
        V_DESCRIPTION: null,
      },
    ],
  },
};
const masterInfluencerRangeState = {
  isLoading: false,
  error: false,
  result: {
    data: [],
  },
};
const masterInfluencerMinimalState = {
  isLoading: false,
  error: false,
  result: {
    data: [],
  },
};
const masterPoliticalPartyState = {
  isLoading: false,
  error: false,
  result: {
    status: 200,
    success: true,
    msg: 'ok',
    data: [],
  },
};
export function MasterNegativityReducer(state = masterState, action) {
  switch (action.type) {
    case 'MASTER_NEGATIVITY_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'MASTER_NEGATIVITY_REDUCER_FULFILLED':
      return {
        ...state, isLoading: false, error: false, result: action.payload,
      };
    case 'MASTER_NEGATIVITY_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function MasterInterestReducer(state = masterState, action) {
  switch (action.type) {
    case 'MASTER_INTEREST_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'MASTER_INTEREST_REDUCER_FULFILLED':
      return {
        ...state, isLoading: false, error: false, result: action.payload,
      };
    case 'MASTER_INTEREST_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function MasterSosmedReducer(state = masterState, action) {
  switch (action.type) {
    case 'MASTER_SOSMED_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'MASTER_SOSMED_REDUCER_FULFILLED':
      return {
        ...state, isLoading: false, error: false, result: action.payload,
      };
    case 'MASTER_SOSMED_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function MasterGradeReducer(state = masterGradeState, action) {
  switch (action.type) {
    case 'MASTER_GRADE_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'MASTER_GRADE_REDUCER_FULFILLED':
      return {
        ...state, isLoading: false, error: false, result: action.payload,
      };
    case 'MASTER_GRADE_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function MasterPoliticalPartyReducer(state = masterPoliticalPartyState, action) {
  switch (action.type) {
    case 'MASTER_POLITICAL_PARTY_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'MASTER_POLITICAL_PARTY_REDUCER_FULFILLED':
      return {
        ...state, isLoading: false, error: false, result: action.payload,
      };
    case 'MASTER_POLITICAL_PARTY_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function MasterInfluencerRangeReducer(state = masterInfluencerRangeState, action) {
  switch (action.type) {
    case 'MASTER_INFLUENCER_RANGE_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'MASTER_INFLUENCER_RANGE_REDUCER_FULFILLED':
      return {
        ...state, isLoading: false, error: false, result: action.payload,
      };
    case 'MASTER_INFLUENCER_RANGE_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function MasterInfluencerMinimalReducer(state = masterInfluencerMinimalState, action) {
  switch (action.type) {
    case 'MASTER_INFLUENCER_MINIMAL_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'MASTER_INFLUENCER_MINIMAL_REDUCER_FULFILLED':
      return {
        ...state, isLoading: false, error: false, result: action.payload,
      };
    case 'MASTER_INFLUENCER_MINIMAL_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
// masterJoblistregister
export function MasterJobListReducer(state = masterState, action) {
  switch (action.type) {
    case 'MASTER_JOB_LIST_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'MASTER_JOB_LIST_REDUCER_FULFILLED':
      return {
        ...state, isLoading: false, error: false, result: action.payload,
      };
    case 'MASTER_JOB_LIST_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
// masterCompanytype
export function MasterCompanyTypeReducer(state = masterState, action) {
  switch (action.type) {
    case 'MASTER_COMPANY_TYPE_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'MASTER_COMPANY_TYPE_REDUCER_FULFILLED':
      return {
        ...state, isLoading: false, error: false, result: action.payload,
      };
    case 'MASTER_COMPANY_TYPE_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function MasterTermPolicyReducer(state = masterState, action) {
  switch (action.type) {
    case 'TERM_CONIDITION_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'TERM_CONIDITION_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: true,
        error: false,
        result: action.payload,
      };
    case 'TERM_CONIDITION_REDUCER_REJECTED':
      return { ...state, isLoading: true, error: false };
    default:
      return state;
  }
}
