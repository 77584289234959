const statePropertyAddedCandidate = {
  batchName: false,
  startDate: false,
  endDate: false,
  batchid: false,
  type: false,
};
export default function (state = statePropertyAddedCandidate, action) {
  switch (action.type) {
    case 'PROPERTY_ADDED_CANDIDATE_REDUCER':
      return { ...state, ...action.state };
    default:
      return state;
  }
}
