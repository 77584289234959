const postFilterState = {
  result: {
    count: 0,
    data: [],
  },
  isLoading: false,
  error: false,
};
const postFilterStateValue = {
  sosmed: 3,
};
const commentPostFilterState = {
  isLoading: [],
  error: false,
  result: {},
};
export function CommentPostFilterReducer(state = commentPostFilterState, action) {
  let f = [];
  switch (action.type) {
    case 'COMMENT_POST_FILTER_REDUCER_PENDING':
      return { ...state, isLoading: [...state.isLoading, action.payload.id], error: false };
    case 'COMMENT_POST_FILTER_REDUCER_FULFILLED':
      f = JSON.parse(JSON.stringify(state.isLoading)).filter((a) => a !== action.payload.id);
      return {
        ...state,
        isLoading: f,
        result: {
          ...state.result,
          [action.payload.id]: action.payload.data,
        },
      };
    case 'COMMENT_POST_FILTER_REDUCER_REJECTED':
      f = JSON.parse(JSON.stringify(state.isLoading)).filter((a) => a !== action.payload.id);
      return { ...state, isLoading: f, error: true };
    default:
      return state;
  }
}
export function PostFilterReducer(state = postFilterState, action) {
  switch (action.type) {
    case 'POST_FILTER_REDUCER_SET_COUNT':
      return { ...state, result: { ...state.result, count: action.count } };
    case 'POST_FILTER_REDUCER_RESET':
      return postFilterState;
    case 'POST_FILTER_REDUCER_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case 'POST_FILTER_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: false,
        result: {
          ...state.result,
          countDataDisplay: action.countDataDisplay,
          countDataDisplayStart: action.countDataDisplayStart,
          countDataDisplayEnd: action.countDataDisplayEnd,
          data: {
            // ...state.result.data,
            ...action.payload,
          },
        },
      };
    case 'POST_FILTER_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function PostFilterReducerCount(state = postFilterStateValue, action) {
  switch (action.type) {
    case 'POST_FILTER_REDUCER_VALUE_SET_COUNT':
      return {
        ...state,
        sosmed: action.payload,
      };
    case 'POST_FILTER_REDUCER_VALUE_RESET_COUNT':
      return {
        ...state,
        sosmed: 3,
      };
    default:
      return state;
  }
}
