const clientInfoState = {
  isLoading: false,
  result: '-',
  error: false,
};
export default function (state = clientInfoState, action) {
  switch (action.type) {
    case 'USER_VALID_REDUCER_PENDING':
      return { ...state, isLoading: true };
    case 'USER_VALID_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'USER_VALID_REDUCER_REJECTED':
      return { ...state, isLoading: true };
    default:
      return state;
  }
}
