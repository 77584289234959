import GlobalReducer from '../reducers/_globalReducer';
import BatchReducer from '../reducers/_batchReducer';
import CandidateNegativityReducer from '../reducers/_candidateNegativityReducer';
import CandidatePotentialReducer from '../reducers/_candidatePontetialReducer';
import CandidatePoliticalPartyReducer from '../reducers/_candidatePoliticalPartyReducer';
import CandidateTopicReducer from '../reducers/_candidateTopicReducer';
import * as ResumeReducer from '../reducers/_resumeReducer';
import * as ActivityReducer from '../reducers/_activityReducer';
import * as DataInstagramReducer from '../reducers/_dataInstagramReducer';
import * as DataFacebookReducer from '../reducers/_dataFacebookReducer';
import * as DataTwitterReducer from '../reducers/_dataTwitterReducer';
import * as DataLinkedinReducer from '../reducers/_dataLinkedinReducer';
import * as MasterReducer from '../reducers/_masterReducer';
import * as DataPostFilterReducer from '../reducers/_dataPostFilterReducer';
import * as HistoryReducer from '../reducers/_historyReducer';
import * as DataJobroleReducer from '../reducers/_dataJobroleReducer';
import * as ValidationReducer from '../reducers/_validationReducer';
import * as PaymentReducer from '../reducers/_paymentReducer';
import * as NotifReducer from '../reducers/_notifReducer';
import * as ClientInfoReducer from '../reducers/_clientInfoReducer';
import ResendOtpReducer from '../reducers/_resendOtpTypeReducer';
import DescriptionBatchReducer from '../reducers/_descriptionBatchReducer';
import DescriptionPersonReducer from '../reducers/_descriptionPersonReducer';
import ErrorMessageReducer from '../reducers/_errorMessageReducer';
import UserValidReducer from '../reducers/_userValidReducer';
import PropertyAddedCandidateReducer from '../reducers/_propertyAddedCandidate';
import PropertyHistoryCreateBatchReducer from '../reducers/_propertyHistoryCreateBatch';
import NotificationReducer from '../reducers/_notificationReducer';
import QuotaReducer from '../reducers/_quotaReducer';
import MessageReducer from '../reducers/_messageRelation';
import ChangeEmailRegistri from '../reducers/_changeEmail';
import PromoReducer from '../reducers/_list_promo';
import PromoRedemReducer from '../reducers/_promoRedem';

export default {
  ...GlobalReducer,
  ...MasterReducer,
  ...BatchReducer,
  ...ResumeReducer,
  ...ActivityReducer,
  ...DataInstagramReducer,
  ...DataFacebookReducer,
  ...DataTwitterReducer,
  ...DataLinkedinReducer,
  ...DataPostFilterReducer,
  ...HistoryReducer,
  ...DataJobroleReducer,
  ...ValidationReducer,
  ...PaymentReducer,
  ...ClientInfoReducer,
  ...NotifReducer,
  ResendOtpReducer,
  MessageReducer,
  ErrorMessageReducer,
  NotificationReducer,
  CandidateNegativityReducer,
  CandidatePotentialReducer,
  CandidateTopicReducer,
  CandidatePoliticalPartyReducer,
  DescriptionBatchReducer,
  DescriptionPersonReducer,
  UserValidReducer,
  PropertyAddedCandidateReducer,
  PropertyHistoryCreateBatchReducer,
  QuotaReducer,
  ChangeEmailRegistri,
  PromoReducer,
  PromoRedemReducer,
};
