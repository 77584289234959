/**
 * @var WHITE_LIST is variable to declare store reducer save in browser;
 */
const WHITE_LIST = [
  'StatusPageReducer',
  'PropsBatchReducer',
  'ClientInfoReducer',
  'PropertyHistoryCreateBatchReducer',
  'PropertyAddedCandidateReducer',
  'PropsStatusBatchReducer',
  'PropsPostFilterReducer',
  'PropertiesKeySecretReducer',
  'StatusAfterSubmitCandidatebatchReducer',
  // 'SosmedTopInteraksiReducer',
  // 'PromoRedemReducer',
  // 'PromoCodeAfterRedemSuccess',
  // 'MasterNegativityReducer',
  // 'MasterInterestReducer',
  // 'MasterSosmedReducer',
  // 'MasterInfluencerRangeReducer',
  // 'MasterInfluencerMinimalReducer',
  // 'ResumeTotalProfileBatchReducer',
  // 'ResumeChartGenderHomeReducer',
  // 'ResumeChartAgeHomeReducer',
  // 'ResumeChartNegativityHomeReducer',
  // 'ResumeChartInterestHomeReducer',
];

export default {
  WHITE_LIST,
};
