const dataPesonalTwitterState = {
  isLoading: false,
  error: false,
  result: {},
};
const sentimentPersonalTwitterState = {
  isLoading: false,
  error: false,
  result: {
    totalContent: {},
    sentiment: {},
  },
};
const topInterestTwitterState = {
  isLoading: false,
  error: false,
  result: {},
};
const topTendencyTwitterReducer = {
  isLoading: false,
  error: false,
  result: {},
};
const topPolpartyTwitterReducer = {
  isLoading: false,
  error: false,
  result: {},
};
const postSosmedTwitterState = {
  isLoading: {
    all: false,
    notAll: false,
  },
  error: false,
  result: {
    notAll: {},
    all: {},
    countall: 0,
    countnotAll: 0,
  },
};
const commentSosmedTwitterState = {
  isLoading: [],
  error: false,
  result: {},
};
const engagementRateTwitterState = {
  isLoading: [],
  error: false,
  result: {},
};
export function EngagementRateTwitterReducer(state = engagementRateTwitterState, action) {
  switch (action.type) {
    case 'ENGAGEMENT_RATE_TWITTER_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'ENGAGEMENT_RATE_TWITTER_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'ENGAGEMENT_RATE_TWITTER_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: false };
    case 'ENGAGEMENT_RATE_TWITTER_REDUCER_RESET':
      return engagementRateTwitterState;
    default:
      return state;
  }
}
export function CommentSosmedTwitterReducer(state = commentSosmedTwitterState, action) {
  let f = [];
  switch (action.type) {
    case 'COMMENT_SOSMED_TWITTER_REDUCER_PENDING':
      return { ...state, isLoading: [...state.isLoading, action.payload.id], error: false };
    case 'COMMENT_SOSMED_TWITTER_REDUCER_FULFILLED':
      f = JSON.parse(JSON.stringify(state.isLoading)).filter((a) => a !== action.payload.id);
      return {
        ...state,
        isLoading: f,
        result: {
          ...state.result,
          [action.payload.id]: action.payload.data,
        },
      };
    case 'COMMENT_SOSMED_TWITTER_REDUCER_REJECTED':
      f = JSON.parse(JSON.stringify(state.isLoading)).filter((a) => a !== action.payload.id);
      return { ...state, isLoading: f, error: true };
    default:
      return state;
  }
}
export function PostSosmedTwitterReducer(state = postSosmedTwitterState, action) {
  let newData = [];
  switch (action.type) {
    case 'POST_SOSMED_TWITTER_REDUCER_PENDING':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.type]: true,
        },
        error: false,
      };
    case 'POST_SOSMED_TWITTER_REDUCER_SET':
      newData = JSON.parse(JSON.stringify(state.result.all));
      newData.push(action.payload);
      return {
        ...state,
        isLoading: false,
        result: {
          ...state.result,
          all: newData,
        },
      };
    case 'POST_SOSMED_TWITTER_REDUCER_SET_NOT_ALL':
      newData = JSON.parse(JSON.stringify(state.result.notAll));
      newData.push(action.payload);
      return {
        ...state,
        isLoading: false,
        result: {
          ...state.result,
          notAll: newData,
        },
      };
    case 'POST_SOSMED_TWITTER_REDUCER_RESET_NOT_ALL':
      return {
        ...state,
        result: {
          ...state.result,
          notAll: {},
        },
      };
    case 'POST_SOSMED_TWITTER_REDUCER_RESET_ALL':
      return {
        ...state,
        result: {
          ...state.result,
          all: {},
        },
      };
    case 'POST_SOSMED_TWITTER_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.type]: false,
        },
        result: {
          ...state.result,
          [action.payload.type]: { ...state.result[action.payload.type], ...action.payload.data },
        },
      };
    case 'POST_SOSMED_TWITTER_REDUCER_REJECTED':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.type]: Boolean(action.payload.isLoading),
        },
        error: true,
      };
    case 'POST_SOSMED_TWITTER_REDUCER_SET_ALL_COUNT':
      return {
        ...state,
        result: {
          ...state.result,
          countall: action.payload,
        },
      };
    case 'POST_SOSMED_TWITTER_REDUCER_SET_NOT_ALL_COUNT':
      return {
        ...state,
        result: {
          ...state.result,
          countnotAll: action.payload,
        },
      };
    default:
      return state;
  }
}
export function DataPersonalTwitterReducer(state = dataPesonalTwitterState, action) {
  switch (action.type) {
    case 'DATA_PERSONAL_TWITTER_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'DATA_PERSONAL_TWITTER_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'DATA_PERSONAL_TWITTER_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function SentimentPersonalTwitterReducer(state = sentimentPersonalTwitterState, action) {
  switch (action.type) {
    case 'SENTIMENT_PERSONAL_TWITTER_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'SENTIMENT_PERSONAL_TWITTER_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'SENTIMENT_PERSONAL_TWITTER_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function TopInterestTwitterReducer(state = topInterestTwitterState, action) {
  switch (action.type) {
    case 'TOP_INTEREST_TWITTER_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'TOP_INTEREST_TWITTER_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'TOP_INTEREST_TWITTER_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function TopTendencyTwitterReducer(state = topTendencyTwitterReducer, action) {
  switch (action.type) {
    case 'TOP_TENDENCY_TWITTER_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'TOP_TENDENCY_TWITTER_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'TOP_TENDENCY_TWITTER_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function TopPoliticalpartyTwitterReducer(state = topPolpartyTwitterReducer, action) {
  switch (action.type) {
    case 'TOP_POLITICALPARTY_TWITTER_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'TOP_POLITICALPARTY_TWITTER_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'TOP_POLITICALPARTY_TWITTER_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
