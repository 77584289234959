import Type from '../redux/typeReducers';

const actionState = {
  isLoading: false,
  error: false,
};
const jobroleChartState = {
  ...actionState,
  results: [],
};

export function ResumeJobroleCountReducer(state = jobroleChartState, action) {
  switch (action.type) {
    case Type.RESUME_JOBROLE_COUNT_PENDING:
      return { ...state, isLoading: true, error: false };
    case Type.RESUME_JOBROLE_COUNT_FULFILLED:
      return { ...state, isLoading: false, results: action.payload };
    case Type.RESUME_JOBROLE_COUNT_REJECTED:
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export const a = '';
