const dataPesonalLinkedinState = {
  isLoading: true,
  error: false,
  result: {},
};
const dataJobsLinkedinState = {
  isLoading: true,
  error: false,
  result: {},
};
const dataEducationLinkedinState = {
  isLoading: true,
  error: false,
  result: {},
};
const dataInterestLinkedinState = {
  isLoading: true,
  error: false,
  result: {},
};
const dataCertificationsLinkedinState = {
  isLoading: true,
  error: false,
  result: {},
};

const dataRecommendationLikedlnState = {
  isLoading: true,
  error: false,
  result: {},
};
export function DataRecomendationLinkedinReducer(state = dataRecommendationLikedlnState, action) {
  switch (action.type) {
    case 'DATA_RECOMENDATION_LINKEDIN_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'DATA_RECOMENDATION_LINKEDIN_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'DATA_RECOMENDATION_LINKEDIN_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    case 'DATA_RECOMENDATION_LINKEDIN_REDUCER_RESET':
      return dataRecommendationLikedlnState;
    default:
      return state;
  }
}
export function DataPersonalLinkedinReducer(state = dataPesonalLinkedinState, action) {
  switch (action.type) {
    case 'DATA_PERSONAL_LINKEDIN_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'DATA_PERSONAL_LINKEDIN_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'DATA_PERSONAL_LINKEDIN_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    case 'DATA_PERSONAL_LINKEDIN_REDUCER_RESET':
      return dataPesonalLinkedinState;
    default:
      return state;
  }
}
export function DataJobsLinkedinReducer(state = dataJobsLinkedinState, action) {
  switch (action.type) {
    case 'DATA_JOBS_LINKEDIN_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'DATA_JOBS_LINKEDIN_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'DATA_JOBS_LINKEDIN_REJECTED':
      return { ...state, isLoading: false, error: true };
    case 'DATA_JOBS_LINKEDIN_RESET':
      return dataJobsLinkedinState;
    default:
      return state;
  }
}
export function DataEducationLinkedinReducer(state = dataEducationLinkedinState, action) {
  switch (action.type) {
    case 'DATA_EDUCATION_LINKEDIN_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'DATA_EDUCATION_LINKEDIN_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'DATA_EDUCATION_LINKEDIN_REJECTED':
      return { ...state, isLoading: false, error: true };
    case 'DATA_EDUCATION_LINKEDIN_RESET':
      return dataEducationLinkedinState;
    default:
      return state;
  }
}
export function DataSkillLinkedinReducer(state = dataEducationLinkedinState, action) {
  switch (action.type) {
    case 'DATA_SKILL_LINKEDIN_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'DATA_SKILL_LINKEDIN_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'DATA_SKILL_LINKEDIN_REJECTED':
      return { ...state, isLoading: false, error: true };
    case 'DATA_SKILL_LINKEDIN_RESET':
      return dataEducationLinkedinState;
    default:
      return state;
  }
}
export function DataInterestLinkedinReducer(state = dataInterestLinkedinState, action) {
  switch (action.type) {
    case 'DATA_INTEREST_LINKEDIN_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'DATA_INTEREST_LINKEDIN_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'DATA_INTEREST_LINKEDIN_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    case 'DATA_INTEREST_LINKEDIN_REDUCER_RESET':
      return dataInterestLinkedinState;
    default:
      return state;
  }
}
export function DataCertificationstLinkedinReducer(state = dataCertificationsLinkedinState, action) {
  switch (action.type) {
    case 'DATA_CERTIFICATIONS_LINKEDIN_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'DATA_CERTIFICATIONS_LINKEDIN_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'DATA_CERTIFICATIONS_LINKEDIN_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    case 'DATA_CERTIFICATIONS_LINKEDIN_REDUCER_RESET':
      return dataCertificationsLinkedinState;
    default:
      return state;
  }
}
