const promoState = {
  promoRedemSuccess: {
    D_PROMO_EXPIRED: '',
    V_MAX_REQUIRED: 0,
    V_MIN_REQUIRED: 0,
    V_PRICE_CUT: '',
    V_PROMO_CODE: '',
    V_PROMO_DESC: '',
    V_PROMO_NAME: '',
  },
  promo: {
    D_PROMO_EXPIRED: '',
    V_MAX_REQUIRED: 0,
    V_MIN_REQUIRED: 0,
    V_PRICE_CUT: '',
    V_PROMO_CODE: '',
    V_PROMO_DESC: '',
    V_PROMO_NAME: '',
  },
  error: '',
  subError: '',
};

export default function PromoRedemReducer(state = promoState, action) {
  switch (action.type) {
    case 'PROMO_REDEM':
      return { ...state, promoRedemSuccess: action.payload };
    case 'PROMO_BATAL_REDEM':
      return {
        ...state,
        promoRedemSuccess: {
          D_PROMO_EXPIRED: '',
          V_MAX_REQUIRED: 0,
          V_MIN_REQUIRED: 0,
          V_PRICE_CUT: '',
          V_PROMO_CODE: '',
          V_PROMO_DESC: '',
          V_PROMO_NAME: '',
        },
      };
    case 'PROMO_BATAL_APPLY':
      return {
        ...state,
        promo: {
          D_PROMO_EXPIRED: '',
          V_MAX_REQUIRED: 0,
          V_MIN_REQUIRED: 0,
          V_PRICE_CUT: '',
          V_PROMO_CODE: '',
          V_PROMO_DESC: '',
          V_PROMO_NAME: '',
        },
      };
    case 'SET_PROMO':
      return { ...state, promo: action.payload };
    case 'SET_ERROR':
      return { ...state, error: action.payload, subError: action.subError };
    case 'SET_ERROR_NULL':
      return { ...state, error: '', subError: '' };
    default:
      return state;
  }
}
