const descriptionBatchState = {
  isLoading: false,
  error: false,
  result: {
    countTwitter: {},
    countFacebook: {},
    countInstagram: {},
    rateTendency: {},
    rateInterest: {},
    ratePoliticalParty: {},
  },
};
export default function (state = descriptionBatchState, action) {
  switch (action.type) {
    case 'DESCTRIPTION_BATCH_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'DESCTRIPTION_BATCH_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'DESCTRIPTION_BATCH_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
