const intialState = {
  isLoading: false,
  data: [],
  error: false,
};

export function ValidationExcelAddReducer(state = intialState, action) {
  const newData = state.data;
  switch (action.type) {
    case 'VALIDATION_EXCEL_ADD_REDUCER_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
        data: [],
      };
    case 'VALIDATION_EXCEL_ADD_REDUCER_FULFILLED':
      return { ...state, isLoading: false, data: action.payload };
    case 'VALIDATION_EXCEL_ADD_ERROR_REDUCER_FULFILLED':
      newData[action.payload.index].V_ERROR_MESSAGE = JSON.stringify(action.payload.error);
      return { ...state, isLoading: false, data: newData };
    case 'VALIDATION_EXCEL_ADD_REDUCER_REJECT':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function ValidationExcelEditReducer(state = intialState, action) {
  const newData = state.data;
  switch (action.type) {
    case 'VALIDATION_EXCEL_EDIT_REDUCER_PENDING':
      return {
        ...state,
        isLoading: true,
        error: false,
        data: [],
      };
    case 'VALIDATION_EXCEL_EDIT_REDUCER_FULFILLED':
      return { ...state, isLoading: false, data: action.payload };
    case 'VALIDATION_EXCEL_EDIT_ERROR_REDUCER_FULFILLED':
      newData[action.payload.index].V_ERROR_MESSAGE = JSON.stringify(action.payload.error);
      return { ...state, isLoading: false, data: newData };
    case 'VALIDATION_EXCEL_EDIT_REDUCER_REJECT':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
