export default {
  // _globalReducer
  LOADING_DOWNLOAD_SET_PROPS: 'LOADING_DOWNLOAD_REDUCER_SET_PROPS',
  PROPS_STATUS_BATCH_PENDING: 'PROPS_STATUS_BATCH_REDUCER_PENDING',
  PROPS_STATUS_BATCH_FULFILLED: 'PROPS_STATUS_BATCH_REDUCER_FULFILLED',
  PROPS_STATUS_BATCH_REJECTED: 'PROPS_STATUS_BATCH_REDUCER_REJECTED',
  STATUS_PAGE_SET: 'STATUS_PAGE_REDUCER_SET',
  STATUS_PAGE_SET_MULTIPLE: 'STATUS_PAGE_REDUCER_SET_MULTIPLE',
  STATUS_PAGE_RESET: 'STATUS_PAGE_REDUCER_RESET',
  STATUS_PAGE_RESET_ALL: 'STATUS_PAGE_REDUCER_RESET_ALL',
  PROPS_BATCH_SET: 'PROPS_BATCH_SET',
  PROPS_BATCH_RESET_ALL: 'PROPS_BATCH_RESET_ALL',
  PROPS_POST_FILTER_SET: 'PROPS_POST_FILTER_SET',
  PROPS_POST_FILTER_RESET_ALL: 'PROPS_POST_FILTER_RESET_ALL',
  // _errorMessage
  ERROR_MESSAGE_SET: 'ERROR_MESSAGE_REDUCER_SET',
  ERROR_MESSAGE_RESET: 'ERROR_MESSAGE_REDUCER_RESET',
  // ResumeJobroleCountReducer
  RESUME_JOBROLE_COUNT_FULFILLED: 'RESUME_JOBROLE_COUNT_REDUCER_FULFILLED',
  RESUME_JOBROLE_COUNT_PENDING: 'RESUME_JOBROLE_COUNT_REDUCER_PENDING',
  RESUME_JOBROLE_COUNT_REJECTED: 'RESUME_JOBROLE_COUNT_REDUCER_REJECTED',
  // ClientInfoReducer
  CLIENT_INFO_REDUCER_PENDING: 'CLIENT_INFO_REDUCER_PENDING',
  CLIENT_INFO_REDUCER_REJECTED: 'CLIENT_INFO_REDUCER_REJECTED',
  CLIENT_INFO_REDUCER_FULFILLED: 'CLIENT_INFO_REDUCER_FULFILLED',
  CLIENT_PROFILE_REDUCER_PENDING: 'CLIENT_PROFILE_REDUCER_PENDING',
  CLIENT_PROFILE_REDUCER_REJECTED: 'CLIENT_PROFILE_REDUCER_REJECTED',
  CLIENT_PROFILE_REDUCER_FULFILLED: 'CLIENT_PROFILE_REDUCER_FULFILLED',

  CHECK_NPWP_REDUCER_PENDING: 'CHECK_NPWP_REDUCER_PENDING',
  CHECK_NPWP_REDUCER_REJECTED: 'CHECK_NPWP_REDUCER_REJECTED',
  CHECK_NPWP_REDUCER_FULFILLED: 'CHECK_NPWP_REDUCER_FULFILLED',
};
