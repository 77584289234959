import AXIOS_BASE from '../../config';
import { PATH } from '../../variables';

const action = {};

action.login = function (values) {
  return AXIOS_BASE.AUTH.post(`${PATH}/auth/login-web`, {
    email: values.username,
    password: values.password,
    remember: false,
  });
};
action.register = function (values) {
  return AXIOS_BASE.AUTH.post(`${PATH}/auth/register`, values);
};
action.accTermCondition = function () {
  const localStorage = JSON.parse(window.localStorage['persist:root']);
  const status = JSON.parse(localStorage.StatusPageReducer);
  return AXIOS_BASE.AUTH.get(`${PATH}/auth/term-policy-check`, {
    headers: {
      Authorization: status.token,
    },
  });
};
action.activationAuth = function (values) {
  return AXIOS_BASE.AUTH.post(`${PATH}/auth/activate`, values);
};
action.resendEmail = function (values) {
  return AXIOS_BASE.AUTH.post(`${PATH}/auth/resend `, values);
};
action.forgotPassword = function (body) {
  return AXIOS_BASE.AUTH.post(`${PATH}/auth/forgot-password`, body);
};
action.forgotPasswordValidate = function (query) {
  return AXIOS_BASE.AUTH.get(`${PATH}/auth/forgot-password-validate?${query}`);
};
action.logout = function () {
  return AXIOS_BASE.AUTH.get(`${PATH}/auth/logout`);
};
action.forgotPasswordChange = function (body) {
  return AXIOS_BASE.AUTH.post(`${PATH}/auth/forgot-password-change`, body);
};
action.resendForgotPassword = function (body) {
  return AXIOS_BASE.AUTH.post(`${PATH}/auth/forgot-password`, body);
  // return AXIOS_BASE.AUTH.post(`${PATH}/auth/forgot-password-resend`, body);
};
action.changeEmailRegistri = function (values) {
  return AXIOS_BASE.AUTH.post(`${PATH}/auth/register-change-email`, {
    C_CLIENT_ID: values.C_CLIENT_ID,
    V_WORK_EMAIL_OLD: values.V_WORK_EMAIL_OLD,
    V_WORK_EMAIL_NEW: values.V_WORK_EMAIL_NEW,
  });
};

action.changePassword = function (values) {
  const localStorage = JSON.parse(window.localStorage['persist:root']);
  const status = JSON.parse(localStorage.StatusPageReducer);
  return AXIOS_BASE.AUTH.post(`${PATH}/auth/change-password`, {
    V_LAST_PASSWORD: values.V_LAST_PASSWORD,
    V_NEW_PASSWORD: values.V_NEW_PASSWORD,
    V_NEW_PASSWORD_CONFIRMATION: values.V_NEW_PASSWORD_CONFIRMATION,
  }, {
    headers: {
      Authorization: status.token,
    },
  });
};
action.keySecret = function () {
  const localStorage = JSON.parse(window.localStorage['persist:root']);
  const status = JSON.parse(localStorage.StatusPageReducer);
  return AXIOS_BASE.AUTH.get(`${PATH}/auth/keysecret`, {
    headers: {
      Authorization: status.token,
    },
  });
};
action.cekPassword = function (values) {
  const localStorage = JSON.parse(window.localStorage['persist:root']);
  const status = JSON.parse(localStorage.StatusPageReducer);
  return AXIOS_BASE.AUTH.post(`${PATH}/auth/check-password`, {
    V_PASSWORD: values.V_PASSWORD,
  }, {
    headers: {
      Authorization: status.token,
    },
  });
};
action.changeEmail = function (values) {
  const localStorage = JSON.parse(window.localStorage['persist:root']);
  const status = JSON.parse(localStorage.StatusPageReducer);
  return AXIOS_BASE.AUTH.post(`${PATH}/auth/change-email`, {
    V_PASSWORD: values.V_PASSWORD,
    V_WORK_EMAIL_NEW: values.V_WORK_EMAIL_NEW,
  }, {
    headers: {
      Authorization: status.token,
    },
  });
};
action.emailValidate = function (values) {
  const localStorage = JSON.parse(window.localStorage['persist:root']);
  const status = JSON.parse(localStorage.StatusPageReducer);
  return AXIOS_BASE.AUTH.post(`${PATH}/auth/change-email-validate`, {
    V_PASSWORD: values.V_PASSWORD,
    V_WORK_EMAIL_NEW: values.V_WORK_EMAIL_NEW,
    V_OTP: values.V_OTP,
  }, {
    headers: {
      Authorization: status.token,
    },
  });
};

export default action;
