const quotaState = {
  isLoading: false,
  error: false,
  result: {
    QUOTA: 0,
  },
};
export default function (state = quotaState, action) {
  switch (action.type) {
    case 'QUOTA_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'QUOTA_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'QUOTA_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
