import REDUCERS from '../redux/typeReducers';

const mainstate = {
  isLoading: false,
  error: false,
};
const clientInfoState = {
  ...mainstate,
  result: {},
};
const clientProfileState = {
  ...mainstate,
  result: {},
};
const checkNPWPState = {
  ...mainstate,
  result: false,
};
export function ClientInfoReducer(state = clientInfoState, action) {
  switch (action.type) {
    case REDUCERS.CLIENT_INFO_REDUCER_PENDING:
      return { ...state, isLoading: true };
    case REDUCERS.CLIENT_INFO_REDUCER_FULFILLED:
      return { ...state, isLoading: false, result: action.payload };
    case REDUCERS.CLIENT_INFO_REDUCER_REJECTED:
      return { ...state, isLoading: true };
    default:
      return state;
  }
}

export function ClientProfileReducer(state = clientProfileState, action) {
  switch (action.type) {
    case REDUCERS.CLIENT_PROFILE_REDUCER_PENDING:
      return { ...state, isLoading: true };
    case REDUCERS.CLIENT_PROFILE_REDUCER_FULFILLED:
      return { ...state, isLoading: false, result: action.payload };
    case REDUCERS.CLIENT_PROFILE_REDUCER_REJECTED:
      return { ...state, isLoading: true };
    default:
      return state;
  }
}

export function CheckNPWPReducer(state = checkNPWPState, action) {
  switch (action.type) {
    case REDUCERS.CHECK_NPWP_REDUCER_PENDING:
      return { ...state, isLoading: true };
    case REDUCERS.CHECK_NPWP_REDUCER_FULFILLED:
      return { ...state, isLoading: false, result: action.payload };
    case REDUCERS.CHECK_NPWP_REDUCER_REJECTED:
      return { ...state, isLoading: true };
    default:
      return state;
  }
}
