// src/mocks/handlers.js
import { rest } from 'msw';
import AXIOS_BASE from '../App/global/config';
import { PATH } from '../App/global/variables';

export const handlers = [
  // master API
  // Handles a POST /master/term-policy request
  rest.get(`http://${process.env.REACT_APP_URL_MASTER}${PATH}/master/super?V_MASTER=politicalparty`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        data: [
          { V_PRIVACY_POLICY: 'anggap privacy' },
        ],
        msg: 'ok',
        status: 200,
        success: true,
      }),
    );
  }),
];
