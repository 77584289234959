import React from 'react';
// import HomeScreen from './modules/Home';
// import LoginScreen from './modules/Login';
// import WrapperBatch from './modules/WrapperBatch';
// screen
const HomeScreen = React.lazy(() => import('./global/components/modules/Home/index'));
const AuthScreen = React.lazy(() => import('./modules/Auth'));
const ForgotPasswordScreen = React.lazy(() => import('./modules/ForgotPassword'));
const ChangePasswordScreen = React.lazy(() => import('./modules/ChangePassword'));
const WrapperBatch = React.lazy(() => import('./modules/WrapperBatch'));
const PostFilter = React.lazy(() => import('./modules/PostFilter'));
const CreateBatch = React.lazy(() => import('./modules/CreateBatch'));
const CreateBatchExcel = React.lazy(() => import('./modules/CreateBatchExcel'));
const HistoryCreateBatch = React.lazy(() => import('./modules/HistoryCreateBatch'));
const Redirect = React.lazy(() => import('./modules/Redirect'));
const ReuploadData = React.lazy(() => import('./modules/ReuploadData'));
const WarningActivation = React.lazy(() => import('./modules/WarningActivation'));
const ErrorLoad = React.lazy(() => import('./modules/ErrorLoad'));
const Transaction = React.lazy(() => import('./modules/WrapperTransaction'));
const Setting = React.lazy(() => import('./modules/Setting'));
const ProfileForm = React.lazy(() => import('./modules/ProfileForm'));
const ChangeEmail = React.lazy(() => import('./modules/ChangeEmail'));
const Notifications = React.lazy(() => import('./global/components/modules/Notifications'));
const OnBoarding = React.lazy(() => import('./modules/OnBoarding'));
const TransactionCallback = React.lazy(() => import('./modules/TransactionCallback'));

/**
 * @var ROUTERS adalah variable router aplikasi
 */
const ROUTERS = [
  {
    path: '/',
    Component: HomeScreen,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/onboarding',
    Component: OnBoarding,
    isPrivate: false,
  },
  {
    path: '/callback-payment',
    Component: TransactionCallback,
    isPrivate: false,
  },
  {
    path: '/home',
    Component: HomeScreen,
    isPrivate: true,
  },
  {
    path: '/notifications',
    Component: Notifications,
    isPrivate: true,
  },
  {
    path: '/auth',
    Component: AuthScreen,
    isPrivate: false,
  },
  {
    path: '/profile',
    Component: ProfileForm,
    isPrivate: true,
  },
  {
    path: '/forgot-password',
    Component: ForgotPasswordScreen,
    isPrivate: false,
  },
  {
    path: '/change-password',
    Component: ChangePasswordScreen,
    isPrivate: false,
  },
  {
    path: '/error',
    Component: ErrorLoad,
    isPrivate: false,
  },
  {
    path: '/batch',
    Component: WrapperBatch,
    isPrivate: true,
  },
  {
    path: '/post-filter',
    Component: PostFilter,
    isPrivate: true,
  },
  {
    path: '/create-batch',
    Component: CreateBatch,
    isPrivate: true,
  },
  {
    path: '/create-batch-excel',
    Component: CreateBatchExcel,
    isPrivate: true,
  },
  {
    path: '/upload-data-candidate',
    Component: ReuploadData,
    isPrivate: true,
  },
  {
    path: '/history-create-batch',
    Component: HistoryCreateBatch,
    isPrivate: true,
  },
  {
    path: '/transaction',
    Component: Transaction,
    isPrivate: true,
  },
  {
    path: '/setting',
    Component: Setting,
    isPrivate: true,
  },
  {
    path: '/redirect',
    Component: Redirect,
    isPrivate: false,
  },
  {
    path: '/activation',
    Component: WarningActivation,
    isPrivate: false,
  },
  {
    path: '/redirect-fp',
    Component: Redirect,
    isPrivate: false,
  },
  {
    path: '/change-email',
    Component: ChangeEmail,
    isPrivate: false,
  },
];

export default ROUTERS;
