const messageState = {
  auth: { message: false, type: false },
  forgotPassword: { message: false, type: false },
  changePassword: { message: false, type: false },
  warningActivation: { message: false, type: false },
};

export default function messageReducer(state = messageState, action) {
  switch (action.type) {
    case 'MESSAGE_REDUCER_SET':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
