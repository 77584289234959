const candidateTopicState = {
  isLoading: {
    all: false,
    notAll: false,
  },
  error: false,
  result: {
    notAll: {},
    all: {},
    countall: 0,
    countnotAll: 0,
  },
};
export default function (state = candidateTopicState, action) {
  let newData = [];
  switch (action.type) {
    case 'CANDIDATE_TOPIC_REDUCER_PENDING':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.type]: true,
        },
        error: false,
      };
    case 'CANDIDATE_TOPIC_REDUCER_SET_COUNT':
      return {
        ...state,
        result: {
          ...state.result,
          countall: action.payload,
        },
      };
    case 'CANDIDATE_TOPIC_REDUCER_SET_NOT_ALL_COUNT':
      return {
        ...state,
        result: {
          ...state.result,
          countnotAll: action.payload,
        },
      };
    case 'CANDIDATE_TOPIC_REDUCER_SET':
      newData = JSON.parse(JSON.stringify(state.result.all));
      newData.push(action.payload);
      return {
        ...state,
        isLoading: false,
        result: {
          ...state.result,
          all: newData,
        },
      };
    case 'CANDIDATE_TOPIC_REDUCER_SET_NOT_ALL':
      newData = JSON.parse(JSON.stringify(state.result.notAll));
      newData.push(action.payload);
      return {
        ...state,
        isLoading: false,
        result: {
          ...state.result,
          notAll: newData,
        },
      };
    case 'CANDIDATE_TOPIC_REDUCER_RESET_NOT_ALL':
      return {
        ...state,
        result: {
          ...state.result,
          notAll: {},
          countnotAll: 0,
        },
      };
    case 'CANDIDATE_TOPIC_REDUCER_RESET_ALL':
      return {
        ...state,
        result: {
          ...state.result,
          all: {},
          countall: 0,
        },
      };
    case 'CANDIDATE_TOPIC_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.type]: false,
        },
        result: {
          ...state.result,
          [action.payload.type]: { ...state.result[action.payload.type], ...action.payload.data },
        },
      };
    case 'CANDIDATE_TOPIC_REDUCER_REJECTED':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.type]: Boolean(action.payload.isLoading),
        },
        error: true,
      };
    default:
      return state;
  }
}
