import AuthAction from './auth';
import BatchAction from './batch';
import MasterAction from './master';
import ResumeAction from './resume';
import FacebookAction from './facebook';
import InstagramAction from './instagram';
import TwitterAction from './twitter';
import LinkedinAction from './linkedin';
import PaymentAction from './payment';
import NotifAction from './notification';

const ACTION = {
  ...AuthAction,
  ...BatchAction,
  ...MasterAction,
  ...ResumeAction,
  ...FacebookAction,
  ...InstagramAction,
  ...TwitterAction,
  ...LinkedinAction,
  ...PaymentAction,
  ...NotifAction,
};

export default ACTION;
