const stateHistory = {
  isLoading: false,
  results: [],
  error: false,
};
export function HistoryCreateBatchReducer(state = stateHistory, action) {
  switch (action.type) {
    case 'HISTORY_CREATE_BATCH_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'HISTORY_CREATE_BATCH_REDUCER_FULFILLED':
      return { ...state, isLoading: false, results: action.payload };
    case 'HISTORY_CREATE_BATCH_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export const a = '';
