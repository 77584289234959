import AXIOS_BASE from '../../config';
import { PATH } from '../../variables';

const action = {};

action.masterNegativity = function () {
  return AXIOS_BASE.MASTER.get(`${PATH}/master/negativity`);
};
action.masterInterest = function () {
  return AXIOS_BASE.MASTER.get(`${PATH}/master/interest`);
};
action.masterSosmed = function () {
  return AXIOS_BASE.MASTER.get(`${PATH}/master/sosmed`);
};
action.masterInfluencerMinimal = function () {
  return AXIOS_BASE.MASTER.get(`${PATH}/master/super?V_MASTER=influencerMinimalValue`);
};
action.masterInfluencerRange = function () {
  return AXIOS_BASE.MASTER.get(`${PATH}/master/super?V_MASTER=influencerFollowerRanger`);
};
action.masterGrade = function () {
  return AXIOS_BASE.MASTER.get(`${PATH}/master/super?V_MASTER=influenceCategory`);
};
action.masterPoliticalParty = function () {
  return AXIOS_BASE.MASTER.get(`${PATH}/master/super?V_MASTER=politicalparty`);
};
action.masterTermPolicy = function () {
  return AXIOS_BASE.MASTER.get(`${PATH}/master/term-policy`);
};
action.masterJoblistregister = function () {
  return AXIOS_BASE.MASTER.get(`${PATH}/master/super-public?V_MASTER=joblistregister`);
};
action.masterCompanytype = function () {
  return AXIOS_BASE.MASTER.get(`${PATH}/master/super-public?V_MASTER=companytype`);
};
export default action;

export function APIMaster() {
  return action.masterTermPolicy();
}
