const dataPesonalInstagramState = {
  isLoading: false,
  error: false,
  result: {},
};
const sentimentPersonalInstagramState = {
  isLoading: false,
  error: false,
  result: {
    totalContent: {},
    sentiment: {},
  },
};

const topInterestInstagramState = {
  isLoading: false,
  error: false,
  result: {},
};

const topTendencyInstagramReducer = {
  isLoading: false,
  error: false,
  result: {},
};

const topPolpartyInstagramReducer = {
  isLoading: false,
  error: false,
  result: {},
};

const postSosmedInstagramState = {
  isLoading: {
    all: false,
    notAll: false,
  },
  error: false,
  result: {
    notAll: [],
    all: [],
    countall: 0,
    countnotAll: 0,
  },
};
const commentSosmedInstagramReducer = {
  isLoading: [],
  error: false,
  result: {},
};
const engagementRateInstagramState = {
  isLoading: false,
  error: false,
  result: {},
};
export function EngagementRateInstagramReducer(state = engagementRateInstagramState, action) {
  switch (action.type) {
    case 'ENGAGEMENT_RATE_INSTAGRAM_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'ENGAGEMENT_RATE_INSTAGRAM_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'ENGAGEMENT_RATE_INSTAGRAM_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    case 'ENGAGEMENT_RATE_INSTAGRAM_REDUCER_RESET':
      return engagementRateInstagramState;
    default:
      return state;
  }
}
export function CommentSosmedInstagramReducer(state = commentSosmedInstagramReducer, action) {
  let f = [];
  switch (action.type) {
    case 'COMMENT_SOSMED_INSTAGRAM_REDUCER_PENDING':
      return { ...state, isLoading: [...state.isLoading, action.payload.id], error: false };
    case 'COMMENT_SOSMED_INSTAGRAM_REDUCER_FULFILLED':
      f = JSON.parse(JSON.stringify(state.isLoading)).filter((a) => a !== action.payload.id);
      return {
        ...state,
        isLoading: f,
        result: {
          ...state.result,
          [action.payload.id]: action.payload.data,
        },
      };
    case 'COMMENT_SOSMED_INSTAGRAM_REDUCER_REJECTED':
      f = JSON.parse(JSON.stringify(state.isLoading)).filter((a) => a !== action.payload.id);
      return { ...state, isLoading: f, error: true };
    default:
      return state;
  }
}
export function PostSosmedInstagramReducer(state = postSosmedInstagramState, action) {
  let newData = [];
  switch (action.type) {
    case 'POST_SOSMED_INSTAGRAM_REDUCER_PENDING':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.type]: true,
        },
        error: false,
      };
    case 'POST_SOSMED_INSTAGRAM_REDUCER_SET':
      newData = JSON.parse(JSON.stringify(state.result.all));
      newData.push(action.payload);
      return {
        ...state,
        isLoading: false,
        result: {
          ...state.result,
          all: newData,
        },
      };
    case 'POST_SOSMED_INSTAGRAM_REDUCER_SET_NOT_ALL':
      newData = JSON.parse(JSON.stringify(state.result.notAll));
      newData.push(action.payload);
      return {
        ...state,
        isLoading: false,
        result: {
          ...state.result,
          notAll: newData,
          countnotAll: 0,
        },
      };
    case 'POST_SOSMED_INSTAGRAM_REDUCER_RESET_ALL':
      return {
        ...state,
        result: {
          ...state.result,
          all: {},
          countall: 0,
        },
      };
    case 'POST_SOSMED_INSTAGRAM_REDUCER_SET_ALL_COUNT':
      return {
        ...state,
        result: {
          ...state.result,
          countall: action.payload,
        },
      };
    case 'POST_SOSMED_INSTAGRAM_REDUCER_SET_NOT_ALL_COUNT':
      return {
        ...state,
        result: {
          ...state.result,
          countnotAll: action.payload,
        },
      };
    case 'POST_SOSMED_INSTAGRAM_REDUCER_RESET_NOT_ALL':
      return {
        ...state,
        result: {
          ...state.result,
          notAll: {},
          countnotAll: 0,
        },
      };
    case 'POST_SOSMED_INSTAGRAM_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.type]: false,
        },
        result: {
          ...state.result,
          [action.payload.type]: { ...state.result[action.payload.type], ...action.payload.data },
        },
      };
    case 'POST_SOSMED_INSTAGRAM_REDUCER_REJECTED':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.type]: Boolean(action.payload.isLoading),
        },
        error: true,
      };
    default:
      return state;
  }
}
export function DataPersonalInstagramReducer(state = dataPesonalInstagramState, action) {
  switch (action.type) {
    case 'DATA_PERSONAL_INSTAGRAM_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'DATA_PERSONAL_INSTAGRAM_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'DATA_PERSONAL_INSTAGRAM_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function SentimentPersonalInstagramReducer(state = sentimentPersonalInstagramState, action) {
  switch (action.type) {
    case 'SENTIMENT_PERSONAL_INSTAGRAM_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'SENTIMENT_PERSONAL_INSTAGRAM_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'SENTIMENT_PERSONAL_INSTAGRAM_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function TopInterestInstagramReducer(state = topInterestInstagramState, action) {
  switch (action.type) {
    case 'TOP_INTEREST_INSTAGRAM_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'TOP_INTEREST_INSTAGRAM_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'TOP_INTEREST_INSTAGRAM_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function TopTendencyInstagramReducer(state = topTendencyInstagramReducer, action) {
  switch (action.type) {
    case 'TOP_TENDENCY_INSTAGRAM_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'TOP_TENDENCY_INSTAGRAM_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'TOP_TENDENCY_INSTAGRAM_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function TopPoliticalpartyInstagramReducer(state = topPolpartyInstagramReducer, action) {
  switch (action.type) {
    case 'TOP_POLITICALPARTY_INSTAGRAM_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'TOP_POLITICALPARTY_INSTAGRAM_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'TOP_POLITICALPARTY_INSTAGRAM_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
