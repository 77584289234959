import Type from '../redux/typeReducers';

const loadingHome = {
  isLoading: true,
};
const StatusAfterSubmitCandidatebatch = {
  batchid: '',
};
const editbatch = {
  isModalUp: false,
  obj: '',
  isLoading: false,
};
const StatusSnackbarHome = {
  isUP: false,
  text: '',
  isAdd: false,
  textBold: '',
  isAct: false,
  textBoldNext: '',
};
const StatusPageState = {
  isAuthenticated: false,
  token: null,
  paseto: null,
  email: null,
  role: null,
  account_type: '',
  first_login: 0,
};
const PropsBatchState = {};
const PropsPostFilterState = {
  filter: {
    Topic: {},
    Negativity: {
      insult: true,
    },
    Politic: {},
    'Sosial Media': {},
  },
  type: false,
};
const PropsStatusBatchState = {
  isLoading: false,
  error: false,
  result: {
    data: [],
    isInterest: false,
    isNegativity: false,
    isJobrole: false,
  },
};
const LoadingDownloadState = {
  label: '',
  isFinish: false,
  progress: 0,
  isVisible: false,
  isError: false,
};
const PropertiesKeySecret = {
  isLoading: false,
  error: false,
  key: '',
  secret: '',
};
const PropertiesUrl = {
  isLoading: false,
  error: false,
  url: '',
};
export function PropertiesUrlReducer(state = PropertiesUrl, action) {
  switch (action.type) {
    case 'PropertiesUrlPending':
      return {
        ...state, isLoading: true, error: false, url: '',
      };
    case 'PropertiesUrlFulfiled':
      return {
        ...state,
        isLoading: false,
        error: false,
        url: action.payload,
      };
    case 'PropertiesUrlRejected':
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    default:
      return state;
  }
}
export function PropertiesKeySecretReducer(state = PropertiesKeySecret, action) {
  switch (action.type) {
    case 'PropertiesKeySecretReducerPending':
      return {
        ...state, isLoading: true, error: false, key: '', secret: '',
      };
    case 'PropertiesKeySecretReducerFulfiled':
      return {
        ...state,
        isLoading: false,
        error: false,
        key: action.key,
        secret: action.secret,
      };
    case 'PropertiesKeySecretReducerRejected':
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    default:
      return state;
  }
}
export function LoadingHome(state = loadingHome, action) {
  switch (action.type) {
    case 'SETFALSELOADINGHOME':
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
export function EditbatchReducer(state = editbatch, action) {
  switch (action.type) {
    case 'EDIT_BATCH_REDUCER_SET':
      return {
        ...state,
        isModalUp: true,
        obj: action.obj,
      };
    case 'EDIT_BATCH_REDUCER_RESET':
      return {
        ...state,
        isModalUp: false,
        obj: '',
        isLoading: false,
      };
    case 'EDIT_BATCH_REDUCER_SUBMIT':
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
}
export function StatusAfterSubmitCandidatebatchReducer(state = StatusAfterSubmitCandidatebatch, action) {
  switch (action.type) {
    case 'STATUS_AFTER_SUMIT_REDUCER_SET':
      return {
        ...state,
        batchid: action.batchid,
      };
    case 'STATUS_AFTER_SUMIT_REDUCER_RESET':
      return {
        ...state,
        batchid: '',
      };
    default:
      return state;
  }
}
export function StatusSnackbarHomeReducer(state = StatusSnackbarHome, action) {
  switch (action.type) {
    case 'SET_UP_SNACKBAR_HOME':
      return {
        ...state,
        isUP: true,
        text: action.text,
        isAdd: action?.isAdd,
        textBold: action?.textBold,
        isAct: action?.isAct,
        textBoldNext: action?.textBoldNext,
      };
    case 'SET_DOWN_SNACKBAR_HOME':
      return {
        ...state,
        isUP: false,
        text: '',
        isAdd: false,
        textBold: '',
        isAct: false,
        textBoldNext: '',
      };
    default:
      return state;
  }
}
export function LoadingDownloadReducer(state = LoadingDownloadState, action) {
  switch (action.type) {
    case Type.LOADING_DOWNLOAD_SET_PROPS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
export function PropsStatusBatchReducer(state = PropsStatusBatchState, action) {
  switch (action.type) {
    case Type.PROPS_STATUS_BATCH_PENDING:
      return {
        ...state, isLoading: true, error: false, result: PropsStatusBatchState.result,
      };
    case Type.PROPS_STATUS_BATCH_FULFILLED:
      return {
        ...state,
        isLoading: false,
        error: false,
        result: action.payload,
      };
    case Type.PROPS_STATUS_BATCH_REJECTED:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    default:
      return state;
  }
}
export function StatusPageReducer(state = StatusPageState, action) {
  switch (action.type) {
    case Type.STATUS_PAGE_SET:
      return { ...state, [action.payload.indexKey]: action.payload.valueKey };
    case Type.STATUS_PAGE_SET_MULTIPLE:
      return { ...state, ...action.payload };
    case Type.STATUS_PAGE_RESET:
      return { ...state, [action.payload.indexKey]: action.payloadd.valueKey };
    case Type.STATUS_PAGE_RESET_ALL:
      return StatusPageState;
    default:
      return state;
  }
}
export function PropsBatchReducer(state = PropsBatchState, action) {
  switch (action.type) {
    case Type.PROPS_BATCH_SET:
      return { ...action.payload };
    case Type.PROPS_BATCH_RESET_ALL:
      return PropsBatchState;
    // case 'RESET_PROPS_BATCH_SET':
    //   return {
    //     ...state,
    //     PropsBatchState: {},
    //   };
    default:
      return state;
  }
}
export function PropsPostFilterReducer(state = PropsPostFilterState, action) {
  switch (action.type) {
    case Type.PROPS_POST_FILTER_SET:
      return { ...action.payload };
    case Type.PROPS_POST_FILTER_RESET_ALL:
      return PropsPostFilterState;
    default:
      return state;
  }
}
export default {
  LoadingHome,
  StatusPageReducer,
  PropsBatchReducer,
  PropsPostFilterReducer,
  PropsStatusBatchReducer,
  LoadingDownloadReducer,
  PropertiesKeySecretReducer,
  PropertiesUrlReducer,
  StatusSnackbarHomeReducer,
  EditbatchReducer,
  StatusAfterSubmitCandidatebatchReducer,
};
