import Axios from 'axios';
import { MESSAGE_ERROR } from '../message';
import typeReducers from '../redux/typeReducers';
import ACTION from './web/api';
import action from './web/api/auth';

export function SetError(message) {
  return (dispatch) => dispatch({
    type: 'ERROR_MESSAGE_REDUCER_SET',
    message,
  });
}
export function SetMessage(screen, message, type) {
  return (dispatch) => {
    dispatch({
      type: 'MESSAGE_REDUCER_SET',
      payload: {
        [screen]: {
          message,
          type,
        },
      },
    });
  };
}
export function ResetError() {
  return (dispatch) => dispatch({
    type: 'ERROR_MESSAGE_REDUCER_RESET',
  });
}
export function SetPropsBatch(payload) {
  return (dispatch) => dispatch({
    type: 'PROPS_BATCH_SET',
    payload,
  });
}
export function ResetPropsBatchAll() {
  return (dispatch) => dispatch({
    type: 'PROPS_BATCH_RESET_ALL',
  });
}
export function SetPostPropsFilter(payload) {
  return (dispatch) => dispatch({
    type: 'PROPS_POST_FILTER_SET',
    payload,
  });
}
export function ResetPostPropsFilterAll() {
  return (dispatch) => dispatch({
    type: 'PROPS_POST_FILTER_RESET_ALL',
  });
}
export function SetStatusPage(indexKey, valueKey) {
  return (dispatch) => {
    dispatch({
      type: 'STATUS_PAGE_REDUCER_SET',
      payload: {
        indexKey,
        valueKey,
      },
    });
  };
}
export function ResetStatusPage(indexKey, valueKey) {
  return (dipsatch) => {
    dipsatch({
      type: 'STATUS_PAGE_REDUCER_RESET',
      payload: {
        indexKey,
        valueKey,
      },
    });
  };
}
export function SetLoadingDownload(data) {
  return (dispatch) => {
    dispatch({
      type: 'LOADING_DOWNLOAD_REDUCER_SET_PROPS',
      payload: data,
    });
  };
}
/**
 * @function ResetStatusPageAll adalah fungsi mendestroy pesist app
 */
export function ResetStatusPageAll() {
  return (dispatch) => {
    dispatch({
      type: 'STATUS_PAGE_REDUCER_RESET_ALL',
    });
    action.logout();
  };
}
export function SetPropertyData(batchName, startDate, endDate, batchid = false, type = false) {
  return (dispatch) => {
    dispatch({
      type: 'PROPERTY_ADDED_CANDIDATE_REDUCER',
      state: {
        batchName,
        startDate,
        endDate,
        batchid,
        type,
      },
    });
  };
}
export function SetPropertyDataHistory(property) {
  return (dispatch) => {
    dispatch({
      type: 'PROPERTY_HISTORY_CREATE_BATCH_REDUCER',
      state: property,
    });
  };
}
export function ActionWithHeader(method, url, body = {}, option = {}) {
  return (dispatch, getState) => {
    const req = Axios[method];
    const { StatusPageReducer } = getState();
    const { token } = StatusPageReducer;
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log(url);
    }
    if (method === 'get') {
      return new Promise((resolve, reject) => {
        req(`${url}`, {
          ...option,
          headers: {
            ...option.headers,
            Authorization: token,
          },
        })
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401 || err.response.data.msg === 'invalid_token' || err.response.data.msg === 'jwt_malformed' || err.response.data.msg === 'jwt_expired') {
                dispatch(ResetStatusPageAll());
              }
            }
            reject(err);
          });
      });
    }
    return new Promise((resolve, reject) => {
      req(`${url}`, body, {
        ...option,
        headers: {
          ...option.headers,
          Authorization: token,
        },
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.data.msg === 'invalid_token' || err.response.data.msg === 'jwt_malformed' || err.response.data.msg === 'jwt_expired') {
              dispatch(ResetStatusPageAll());
            }
          }
          reject(err);
        });
    });
  };
}

export function SetStatusPageMultiple(obj) {
  return (dispatch) => {
    dispatch({
      type: 'STATUS_PAGE_REDUCER_SET_MULTIPLE',
      payload: obj,
    });
  };
}
export function GetMasterNegativity() {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const { PropsStatusBatchReducer } = getState();
    if (PropsStatusBatchReducer.result.isNegativity) {
      dispatch({ type: 'MASTER_NEGATIVITY_REDUCER_PENDING' });
      ACTION.masterNegativity()
        .then((res) => {
          dispatch({
            type: 'MASTER_NEGATIVITY_REDUCER_FULFILLED',
            payload: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: 'MASTER_NEGATIVITY_REDUCER_REJECTED',
          });
          reject(err);
        });
    } else {
      resolve();
    }
  });
}
export function GetMasterJobList() {
  return (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: 'MASTER_JOB_LIST_REDUCER_PENDING' });
    ACTION.masterJoblistregister()
      .then((res) => {
        dispatch({
          type: 'MASTER_JOB_LIST_REDUCER_FULFILLED',
          payload: res.data,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: 'MASTER_JOB_LIST_REDUCER_REJECTED',
        });
        reject(err);
      });
  });
}
export function GetMasterCompanyType() {
  return (dispatch) => new Promise((resolve, reject) => {
    dispatch({ type: 'MASTER_COMPANY_TYPE_REDUCER_PENDING' });
    ACTION.masterCompanytype()
      .then((res) => {
        dispatch({
          type: 'MASTER_COMPANY_TYPE_REDUCER_FULFILLED',
          payload: res.data,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: 'MASTER_COMPANY_TYPE_REDUCER_REJECTED',
        });
        reject(err);
      });
  });
}
export function GetMasterInterest() {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const { PropsStatusBatchReducer } = getState();
    if (PropsStatusBatchReducer.result.isInterest) {
      dispatch({ type: 'MASTER_INTEREST_REDUCER_PENDING' });
      ACTION.masterInterest()
        .then((res) => {
          dispatch({
            type: 'MASTER_INTEREST_REDUCER_FULFILLED',
            payload: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: 'MASTER_INTEREST_REDUCER_REJECTED',
          });
          reject(err);
        });
    } else {
      resolve();
    }
  });
}
export function CheckProfile() {
  return (dispatch) => new Promise((resolve, reject) => {
    dispatch({
      type: typeReducers.CHECK_NPWP_REDUCER_PENDING,
    });
    ACTION.transactionCheck()
      .then((res) => {
        dispatch({
          type: typeReducers.CHECK_NPWP_REDUCER_FULFILLED,
          payload: res.data.msg,
        });
        resolve(res);
      })
      .catch((err) => {
        if (err.response.data.msg === 'invalid_client_profile_requirement') {
          dispatch({
            type: typeReducers.CHECK_NPWP_REDUCER_FULFILLED,
            payload: err.response.data.msg,
          });
          resolve(err.response);
        } else {
          dispatch({
            type: typeReducers.CHECK_NPWP_REDUCER_REJECTED,
          });
          reject(err);
        }
      });
  });
}
export function GetCheckGrantTenin() {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const { PropsBatchReducer } = getState();
    dispatch({
      type: 'PROPS_STATUS_BATCH_REDUCER_PENDING',
    });
    ACTION.checkAccess(PropsBatchReducer.dataBatch.C_BATCH_ID)
      .then((res) => {
        if (res.data.msg === 'ok') {
          const isInterest = res.data.data.find((da) => (da.V_VALUE === 'Interest'));
          const isNegativity = res.data.data.find((da) => (da.V_VALUE === 'Negativity'));
          const isPoliticalParty = res.data.data.find((da) => (da.V_VALUE === 'Political Party'));
          const isJobrole = true;
          dispatch({
            type: 'PROPS_STATUS_BATCH_REDUCER_FULFILLED',
            payload: {
              data: res.data.data,
              isInterest,
              isNegativity,
              isJobrole,
              isPoliticalParty,
            },
          });
          resolve({
            data: res.data.data,
            isInterest,
            isNegativity,
            isPoliticalParty,
          });
        } else {
          dispatch({
            type: 'PROPS_STATUS_BATCH_REDUCER_FULFILLED',
            payload: {
              data: [],
              isInterest: false,
              isNegativity: false,
              isPoliticalParty: false,
            },
          });
          resolve([]);
        }
      })
      .catch((err) => {
        dispatch({
          type: 'PROPS_STATUS_BATCH_REDUCER_REJECTED',
          payload: err,
        });
        reject(err);
      });
  });
}

export function GetMasterSosmed() {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const { MasterSosmedReducer } = getState();
    if (MasterSosmedReducer.result.data.length === 0) {
      dispatch({ type: 'MASTER_SOSMED_REDUCER_PENDING' });
      ACTION.masterSosmed()
        .then((res) => {
          dispatch({
            type: 'MASTER_SOSMED_REDUCER_FULFILLED',
            payload: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: 'MASTER_SOSMED_REDUCER_REJECTED',
          });
          reject(err);
        });
    }
  });
}
export function GetMasterInfluencerMinimal() {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const { MasterInfluencerMinimalReducer } = getState();
    if (MasterInfluencerMinimalReducer.result.data.length === 0) {
      dispatch({ type: 'MASTER_INFLUENCER_MINIMAL_REDUCER_PENDING' });
      ACTION.masterInfluencerMinimal()
        .then((res) => {
          dispatch({
            type: 'MASTER_INFLUENCER_MINIMAL_REDUCER_FULFILLED',
            payload: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: 'MASTER_INFLUENCER_MINIMAL_REDUCER_REJECTED',
          });
          reject(err);
        });
    } else {
      resolve();
    }
  });
}
export function GetMasterInfluencerRange() {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const { MasterInfluencerRangeReducer } = getState();
    if (MasterInfluencerRangeReducer.result.data.length === 0) {
      dispatch({ type: 'MASTER_INFLUENCER_RANGE_REDUCER_PENDING' });
      ACTION.masterInfluencerRange()
        .then((res) => {
          dispatch({
            type: 'MASTER_INFLUENCER_RANGE_REDUCER_FULFILLED',
            payload: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: 'MASTER_INFLUENCER_RANGE_REDUCER_REJECTED',
          });
          reject(err);
        });
    } else {
      resolve();
    }
  });
}
export function GetMasterGrade() {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const { MasterGradeReducer } = getState();
    if (MasterGradeReducer.result.data.length === 0) {
      dispatch({ type: 'MASTER_GRADE_REDUCER_PENDING' });
      ACTION.masterGrade()
        .then((res) => {
          dispatch({
            type: 'MASTER_GRADE_REDUCER_FULFILLED',
            payload: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: 'MASTER_GRADE_REDUCER_REJECTED',
          });
          reject(err);
        });
    } else {
      resolve();
    }
  });
}

export function GetMasterPoliticalParty() {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const { MasterPoliticalPartyReducer } = getState();
    if (MasterPoliticalPartyReducer.result.data.length === 0) {
      dispatch({ type: 'MASTER_POLITICAL_PARTY_REDUCER_PENDING' });
      ACTION.masterPoliticalParty()
        .then((res) => {
          dispatch({
            type: 'MASTER_POLITICAL_PARTY_REDUCER_FULFILLED',
            payload: res.data,
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: 'MASTER_POLITICAL_PARTY_REDUCER_REJECTED',
          });
          reject(err);
        });
    } else {
      resolve();
    }
  });
}

export function ExportExcel(url) {
  return (dispatch, getState) => {
    const { StatusPageReducer } = getState();
    // ${PropsBatchReducer.dataBatch.C_BATCH_ID}
    const { token } = StatusPageReducer;
    return new Promise((resolve) => {
      fetch(url, {
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin',
        headers: {
          'user-agent': 'Mozilla/4.0 MDN Example',
          'content-type': 'application/vnd.ms-excel;charset=UTF-8',
          Authorization: token,
        },
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
      })
        .then((res) => res.blob().then((blob) => {
          let filename = decodeURI(res.headers.get('Content-Disposition').split('filename=')[1]);// 获取后端headers里面的文件名
          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(blob, filename); // 兼容ie10
          } else {
            filename = filename.split('"').join('');
            const a = document.createElement('a');
            document.body.appendChild(a); // 兼容火狐，将a标签添加到body当中
            a.href = window.URL.createObjectURL(blob);
            a.download = `${filename}`;
            a.target = '_blank'; // a标签增加target属性
            a.click();
            a.remove();// 移除a标签
            window.URL.revokeObjectURL(url);
          }
          resolve();
        }));
    });
  };
}
export function GetMaster() {
  return (dispatch) => Promise.all([
    new Promise((resolve, reject) => {
      dispatch(GetMasterNegativity())
        .then((res) => {
          resolve(res);
        })
        .then((res) => {
          reject(res);
        });
    }),
    new Promise((resolve, reject) => {
      dispatch(GetMasterInterest())
        .then((res) => {
          resolve(res);
        })
        .then((res) => {
          reject(res);
        });
    }),
    new Promise((resolve, reject) => {
      dispatch(GetMasterSosmed())
        .then((res) => {
          resolve(res);
        })
        .then((res) => {
          reject(res);
        });
    }),
    new Promise((resolve, reject) => {
      dispatch(GetMasterGrade())
        .then((res) => {
          resolve(res);
        })
        .then((res) => {
          reject(res);
        });
    }),
    new Promise((resolve, reject) => {
      dispatch(GetMasterPoliticalParty())
        .then((res) => {
          resolve(res);
        })
        .then((res) => {
          reject(res);
        });
    }),
    new Promise((resolve, reject) => {
      dispatch(GetMasterInfluencerRange())
        .then((res) => {
          resolve(res);
        })
        .then((res) => {
          reject(res);
        });
    }),
    new Promise((resolve, reject) => {
      dispatch(GetMasterInfluencerMinimal())
        .then((res) => {
          resolve(res);
        })
        .then((res) => {
          reject(res);
        });
    }),
  ]);
}
export function GetDefaultDate(sosmed = false) {
  return (dispatch, getState) => new Promise((resolve, reject) => {
    const { PropsBatchReducer } = getState();
    const params = {
      personal_id: PropsBatchReducer.dataPersonal.C_PERSONAL_ID,
      sosmed,
    };
    ACTION.lastActivityDatePersonal(params)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function GetQuota() {
  return (dispatch) => new Promise((resolve, reject) => {
    dispatch({
      type: 'QUOTA_REDUCER_PENDING',
    });
    Promise.all([
      ACTION.quotaCompany(),
      ACTION.quotaUsageCompany(),
    ])
      .then((res) => {
        dispatch({
          type: 'QUOTA_REDUCER_FULFILLED',
          payload: res[0].data.data[0],
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: 'QUOTA_REDUCER_REJECTED',
          payload: err,
        });
        reject(err);
      });
  });
}
export function GetListNotif() {
  return (dispatch) => new Promise((resolve, reject) => {
    dispatch({
      type: 'NOTIF_REDUCER_PENDING',
    });
    ACTION.listNotif()
      .then((res) => {
        dispatch({
          type: 'NOTIF_REDUCER_FULFILLED',
          payload: res.data.data,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: 'NOTIF_REDUCER_REJECTED',
        });
        reject(err);
      });
  });
}
export function GetListNotifAll(setNotifs, LIMIT, setIsLoadingLimit, setisLoading) {
  return (dispatch) => new Promise((resolve, reject) => {
    setIsLoadingLimit(true);
    ACTION.listNotifAll(LIMIT)
      .then((res) => {
        setisLoading(false);
        setIsLoadingLimit(false);
        setNotifs(res.data.data);
        resolve(res);
      })
      .catch((err) => {
        setisLoading(false);
        setIsLoadingLimit(false);
        reject(err);
      });
  });
}
export function SetActionNotif(key, data) {
  return (dispatch) => new Promise((resolve) => {
    dispatch({
      type: 'ACTION_NOTIF_SET',
      payload: {
        key,
        data,
      },
    });
    resolve();
  });
}

export function ErrorMessage(dispatch, code) {
  dispatch(SetError(MESSAGE_ERROR[code]));
}

export function ActCheckExpiredTransaction() {
  return (dispatch) => new Promise((resolve, reject) => {
    ACTION.checkExpiredTransaction()
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
