import AXIOS_BASE from '../../config';
import { PATH } from '../../variables';

const action = {};

action.dataPersonalFacebook = function (sosmedId) {
  return AXIOS_BASE.FACEBOOK.get(
    `${PATH}/facebook/general`
    + `?C_SOSMED_PERSONAL_ID=${sosmedId}`,
  );
};
action.dataSosmedUrlFacebook = function (sosmedId) {
  return AXIOS_BASE.FACEBOOK.get(
    `${PATH}/facebook/sosmed-url`
    + `?C_SOSMED_PERSONAL_ID=${sosmedId}`,
  );
};
action.lastActivityDateFacebook = function (sosmedId) {
  return AXIOS_BASE.FACEBOOK.get(
    `${PATH}/facebook/last-activity-date`
    + `?C_SOSMED_PERSONAL_ID=${sosmedId}`,
  );
};
action.totalContentFacebook = function (sosmedId) {
  return AXIOS_BASE.FACEBOOK.get(
    `${PATH}/facebook/total-content`
    + `?C_SOSMED_PERSONAL_ID=${sosmedId}`,
  );
};
action.totalSentimentFacebook = function (sosmedId) {
  return AXIOS_BASE.FACEBOOK.get(
    `${PATH}/facebook/total-sentiment`
    + `?C_SOSMED_PERSONAL_ID=${sosmedId}`,
  );
};
action.topInterestFacebook = function (query) {
  return AXIOS_BASE.FACEBOOK.get(
    `${PATH}/facebook/top-interest`
    + `?${query}`,
  );
};
action.topTendencyFacebook = function (query) {
  return AXIOS_BASE.FACEBOOK.get(
    `${PATH}/facebook/top-tendency`
    + `?${query}`,
  );
};
action.topPoliticalpartyFacebook = function (query) {
  return AXIOS_BASE.FACEBOOK.get(
    `${PATH}/facebook/top-politicalparty`
    + `?${query}`,
  );
};
action.postSosmedFacebook = function (query, config) {
  return AXIOS_BASE.FACEBOOK.get(
    `${PATH}/facebook/posts`
    + `?${query}`,
    config,
  );
};
action.postSosmedCommentFacebook = function (query) {
  return AXIOS_BASE.FACEBOOK.get(
    `${PATH}/facebook/posts/comments`
    + `?${query}`,
  );
};
action.activityPersonalFacebook = function (query) {
  return AXIOS_BASE.FACEBOOK.get(
    `${PATH}/facebook/activity`
    + `?${query}`,
  );
};
export default action;
