export const BATCH_DATA_SEARCHBAR_PENDING = 'BATCH_DATA_SEARCHBAR_PENDING';
export const BATCH_DATA_SEARCHBAR_FULFILLED = 'BATCH_DATA_SEARCHBAR_FULFILLED';
export const BATCH_DATA_SEARCHBAR_REJECTED = 'BATCH_DATA_SEARCHBAR_REJECTED';
export const BATCH_DATA_SEARCHBAR_RESET = 'BATCH_DATA_SEARCHBAR_RESET';
export const BATCH_DATA_REDUCER_SET_LIMIT = 'BATCH_DATA_REDUCER_SET_LIMIT';

const batchDataState = {
  isLoading: false,
  error: false,
  results: [],
  LIMIT: 20,
};
const batchDataListPeopleState = {
  isLoading: {
    all: false,
    notAll: false,
  },
  error: false,
  result: {
    notAll: {},
    all: {},
    countall: 0,
    countnotAll: 0,
  },
};
const batchDataCountPeopleState = {
  isLoading: false,
  error: false,
  result: [],
};
const detailUploadBatchState = {
  result: {
    V_FACEBOOK_TOTAL: 0,
    V_INSTAGRAM_TOTAL: 0,
    V_LINKEDIN_TOTAL: 0,
    V_PERSON_TOTAL: 0,
    V_TWITTER_TOTAL: 0,
  },
  error: false,
  isLoading: false,
};
const exisitingDataState = {
  result: [],
  error: false,
  isLoading: false,
};
const batchDataSearchBar = {
  isLoading: false,
  error: false,
  person: [],
  batch: [],
};
export function BatchDataReducer(state = batchDataState, action) {
  switch (action.type) {
    case 'BATCH_DATA_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'BATCH_DATA_REDUCER_FULFILLED':
      return { ...state, isLoading: false, results: action.payload };
    case 'BATCH_DATA_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    case BATCH_DATA_REDUCER_SET_LIMIT:
      return { ...state, LIMIT: state.LIMIT + 20 };
    case 'BATCH_DATA_REDUCER_RESET':
      return {
        ...state,
        isLoading: false,
        error: false,
        results: [],
        LIMIT: 20,
      };
    default:
      return state;
  }
}
export function BatchDataListPeopleReducer(state = batchDataListPeopleState, action) {
  let newData = [];
  switch (action.type) {
    case 'BATCH_DATA_LIST_PERSON_REDUCER_PENDING':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.type]: true,
        },
        error: false,
      };
    case 'BATCH_DATA_LIST_PERSON_REDUCER_SET_COUNT':
      return {
        ...state,
        result: {
          ...state.result,
          countall: action.payload,
        },
      };
    case 'BATCH_DATA_LIST_PERSON_REDUCER_SET_NOT_ALL_COUNT':
      return {
        ...state,
        result: {
          ...state.result,
          countnotAll: action.payload,
        },
      };
    case 'BATCH_DATA_LIST_PERSON_REDUCER_SET':
      newData = JSON.parse(JSON.stringify(state.result.all));
      newData.push(action.payload);
      return {
        ...state,
        isLoading: false,
        result: {
          ...state.result,
          all: newData,
        },
      };
    case 'BATCH_DATA_LIST_PERSON_REDUCER_SET_NOT_ALL':
      newData = JSON.parse(JSON.stringify(state.result.notAll));
      newData.push(action.payload);
      return {
        ...state,
        isLoading: false,
        result: {
          ...state.result,
          notAll: newData,
        },
      };
    case 'BATCH_DATA_LIST_PERSON_REDUCER_RESET_NOT_ALL':
      return {
        ...state,
        result: {
          ...state.result,
          notAll: {},
          countnotAll: 0,
        },
      };
    case 'BATCH_DATA_LIST_PERSON_REDUCER_RESET_ALL':
      return {
        ...state,
        result: {
          ...state.result,
          all: {},
          countall: 0,
        },
      };
    case 'BATCH_DATA_LIST_PERSON_REDUCER_RESET':
      return {
        ...state,
        isLoading: {
          all: false,
          notAll: false,
        },
        error: false,
        result: {
          notAll: {},
          all: {},
          countall: 0,
          countnotAll: 0,
        },
      };
    case 'BATCH_DATA_LIST_PERSON_REDUCER_FULFILLED':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.type]: false,
        },
        result: {
          ...state.result,
          [action.payload.type]: { ...state.result[action.payload.type], ...action.payload.data },
        },
      };
    case 'BATCH_DATA_LIST_PERSON_REDUCER_REJECTED':
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [action.payload.type]: Boolean(action.payload.isLoading),
        },
        error: true,
      };
    default:
      return state;
  }
}
export function BatchDataCountPeopleReducer(state = batchDataCountPeopleState, action) {
  switch (action.type) {
    case 'BATCH_DATA_COUNT_PEOPLE_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'BATCH_DATA_COUNT_PEOPLE_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'BATCH_DATA_COUNT_PEOPLE_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function DetailUploadBatchReducer(state = detailUploadBatchState, action) {
  switch (action.type) {
    case 'DETAIL_UPLOAD_BATCH_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'DETAIL_UPLOAD_BATCH_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'DETAIL_UPLOAD_BATCH_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function ExisitingDataReducer(state = exisitingDataState, action) {
  switch (action.type) {
    case 'EKSISTING_DATA_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'EKSISTING_DATA_REDUCER_FULFILLED':
      return { ...state, isLoading: false, result: action.payload };
    case 'EKSISTING_DATA_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}
export function BatchDataSearchBar(state = batchDataSearchBar, action) {
  switch (action.type) {
    case BATCH_DATA_SEARCHBAR_PENDING:
      return { ...state, isLoading: true, error: false };
    case BATCH_DATA_SEARCHBAR_FULFILLED:
      return {
        ...state, isLoading: false, person: action.person, batch: action.batch,
      };
    case BATCH_DATA_SEARCHBAR_REJECTED:
      return {
        ...state, isLoading: false, error: true, person: [], batch: [],
      };
    case BATCH_DATA_SEARCHBAR_RESET:
      return {
        ...state, isLoading: false, error: false, person: [], batch: [],
      };
    default:
      return state;
  }
}
export default {
  BatchDataReducer,
  BatchDataListPeopleReducer,
  BatchDataCountPeopleReducer,
  DetailUploadBatchReducer,
  ExisitingDataReducer,
  BatchDataSearchBar,
};
