import AXIOS_BASE from '../../config';
import { PATH } from '../../variables';

const action = {};

action.dataPersonalTwitter = function (sosmedId) {
  return AXIOS_BASE.TWITTER.get(
    `${PATH}/twitter/general`
    + `?C_SOSMED_PERSONAL_ID=${sosmedId}`,
  );
};
action.dataSosmedUrlTwitter = function (sosmedId) {
  return AXIOS_BASE.TWITTER.get(
    `${PATH}/twitter/sosmed-url`
    + `?C_SOSMED_PERSONAL_ID=${sosmedId}`,
  );
};
action.lastActivityDateTwitter = function (sosmedId) {
  return AXIOS_BASE.TWITTER.get(
    `${PATH}/twitter/last-activity-date`
    + `?C_SOSMED_PERSONAL_ID=${sosmedId}`,
  );
};
action.totalContentTwitter = function (sosmedId) {
  return AXIOS_BASE.TWITTER.get(
    `${PATH}/twitter/total-content`
    + `?C_SOSMED_PERSONAL_ID=${sosmedId}`,
  );
};
action.totalSentimentTwitter = function (sosmedId) {
  return AXIOS_BASE.TWITTER.get(
    `${PATH}/twitter/total-sentiment`
    + `?C_SOSMED_PERSONAL_ID=${sosmedId}`,
  );
};
action.topInterestTwitter = function (query) {
  return AXIOS_BASE.TWITTER.get(
    `${PATH}/twitter/top-interest`
    + `?${query}`,
  );
};
action.topTendencyTwitter = function (query) {
  return AXIOS_BASE.TWITTER.get(
    `${PATH}/twitter/top-tendency`
    + `?${query}`,
  );
};
action.topPoliticalpartyTwitter = function (query) {
  return AXIOS_BASE.TWITTER.get(
    `${PATH}/twitter/top-politicalparty`
    + `?${query}`,
  );
};
action.engagementRateTwitter = function (sosmedId) {
  return AXIOS_BASE.TWITTER.get(
    `${PATH}/twitter/engagement-rate`
    + `?C_SOSMED_PERSONAL_ID=${sosmedId}`,
  );
};
action.postSosmedTwitter = function (query, config) {
  return AXIOS_BASE.TWITTER.get(
    `${PATH}/twitter/tweets`
    + `?${query}`,
    config,
  );
};
action.postSosmedCommentTwitter = function (query) {
  return AXIOS_BASE.TWITTER.get(
    `${PATH}/twitter/posts/comments`
    + `?${query}`,
  );
};
action.activityPersonalTwitter = function (query) {
  return AXIOS_BASE.TWITTER.get(
    `${PATH}/twitter/activity`
    + `?${query}`,
  );
};
export default action;
