const stateHistory = {
  isLoading: false,
  results: [],
  error: false,
};
const stateAction = {
  openModalAddedCandidate: false,
};

export function NotifReducer(state = stateHistory, action) {
  switch (action.type) {
    case 'NOTIF_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'NOTIF_REDUCER_FULFILLED':
      return { ...state, isLoading: false, results: action.payload };
    case 'NOTIF_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
}

export function ActionNotifReducer(state = stateAction, action) {
  switch (action.type) {
    case 'ACTION_NOTIF_SET':
      return { ...state, [action.payload.key]: action.payload.data };
    default:
      return state;
  }
}
