import React, { useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Lottie from 'lottie-web';
import animationData from '../dist/Lottie/Loading.json';
import animationDataSmall from '../dist/Lottie/small_loading.json';
import products from '../dist/Lottie/products.json';

const useStyles = makeStyles({
  wrapperLoadingScreen: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 2000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: '.4s ease',
    visibility: 'hidden',
    '&.active': {
      visibility: 'visible',
    },
  },
  image: {
    width: '10%',
    maxWidth: '500px',
  },
  wrapperLoadingWrapperBatch: {
    height: 'calc(100vh - 68px )',
    background: '#F9FAFC',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: '.4s ease',
  },
  wrapperLoadingWrapperSosmed: {
    height: 'calc(100vh - 68px - 71px )',
    background: '#F9FAFC',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: '.4s ease',
  },
  wrapperFull: {
    display: 'flex',
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export function Product() {
  const wrapperLottie = useRef(null);
  useEffect(() => {
    const lottieAction = Lottie.loadAnimation({
      container: wrapperLottie.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: products, // the path to the animation json
    });
    lottieAction.play();
    return () => {
      lottieAction.stop();
      lottieAction.destroy();
    };
  }, []);
  return <div data-testid='product' style={{ width: '100%', height: '100%' }} ref={wrapperLottie}/>;
}

export function LoadingSmall() {
  const wrapperLottie = useRef(null);
  useEffect(() => {
    const lottieAction = Lottie.loadAnimation({
      container: wrapperLottie.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationDataSmall, // the path to the animation json
    });
    lottieAction.play();
    return () => {
      lottieAction.stop();
    };
  }, []);
  return <div data-testid='LoadingSmall' style={{ width: '100%', height: '100%' }} ref={wrapperLottie}/>;
}
export function LoadingFull() {
  const classes = useStyles();
  const wrapperLottie = useRef(null);
  useEffect(() => {
    const lottieAction = Lottie.loadAnimation({
      container: wrapperLottie.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData, // the path to the animation json
    });
    lottieAction.play();
    return () => {
      lottieAction.stop();
    };
  }, []);
  return <div data-testid='LoadingFull' className={classes.wrapperFull}>
      <div className={classes.image} ref={wrapperLottie}/>
  </div>;
}
export function LoadingScreen({ style, isAvaliable }) {
  const classes = useStyles();
  const wrapperLottie = useRef(null);
  useEffect(() => {
    const lottieAction = Lottie.loadAnimation({
      container: wrapperLottie.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData, // the path to the animation json
    });
    lottieAction.play();
    return () => {
      lottieAction.stop();
    };
  }, []);
  return (
    <div data-testid='LoadingScreen' className={`${classes.wrapperLoadingScreen} ${isAvaliable && 'active'}`} style={style}>
      <div className={classes.image} ref={wrapperLottie}/>
    </div>
  );
}

export function LoadingWrapperBatch() {
  const classes = useStyles();
  const wrapperLottie = useRef(null);
  useEffect(() => {
    const lottieAction = Lottie.loadAnimation({
      container: wrapperLottie.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData, // the path to the animation json
    });
    lottieAction.play();
    return () => {
      lottieAction.stop();
    };
  }, []);
  return (
    <div data-testid='LoadingWrapperBatch' className={classes.wrapperLoadingWrapperBatch}>
      <div className={classes.image} ref={wrapperLottie}/>
    </div>
  );
}

export function LoadingWrapperSosmed() {
  const classes = useStyles();
  const wrapperLottie = useRef(null);
  useEffect(() => {
    const lottieAction = Lottie.loadAnimation({
      container: wrapperLottie.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData, // the path to the animation json
    });
    lottieAction.play();
    return () => {
      lottieAction.stop();
    };
  }, []);
  return (
    <div data-testid='LoadingWrapperSosmed' className={classes.wrapperLoadingWrapperSosmed}>
      <div className={classes.image} ref={wrapperLottie}/>
    </div>
  );
}

export function LoadingSearch() {
  const classes = useStyles();
  const wrapperLottie = useRef(null);
  useEffect(() => {
    const lottieAction = Lottie.loadAnimation({
      container: wrapperLottie.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData, // the path to the animation json
    });
    lottieAction.play();
    return () => {
      lottieAction.stop();
    };
  }, []);
  return <div data-testid='LoadingSearch' className={classes.wrapperFull}>
      <div className={classes.image} ref={wrapperLottie}/>
  </div>;
}
export function MoreBtn() {
  const wrapperLottie = useRef(null);
  useEffect(() => {
    const lottieAction = Lottie.loadAnimation({
      container: wrapperLottie.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: animationDataSmall, // the path to the animation json
    });
    lottieAction.play();
    return () => {
      lottieAction.stop();
    };
  }, []);
  return <div style={{
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  }}>
      <div data-testid='LoadingSmall' style={{ width: '5%', height: '5%' }} ref={wrapperLottie}/>
  </div>;
}
