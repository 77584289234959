import Type from '../redux/typeReducers';

const message = false;
export default function (
  state = message,
  action,
) {
  switch (action.type) {
    case Type.ERROR_MESSAGE_SET:
      return action.message;
    case Type.ERROR_MESSAGE_RESET:
      return false;
    default:
      return state;
  }
}
