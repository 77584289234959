const initialState = {
  loading: false,
  error: false,
};
const transactionListPackageState = {
  ...initialState,
  data: [],
};
const transactionPaymentState = {
  ...initialState,
  data: [],
};
const transactionHistoryState = {
  ...initialState,
  data: [],
};
const paymentHistoryState = {
  ...initialState,
  data: [],
};
const cartTransactionState = {
  ...initialState,
  data: [],
};
const paymentConfirmationState = {
  ...initialState,
  data: {},
};
const PromoCodeAfterRedemSuccessState = {
  loading: false,
  error: false,
  data: {},
};
export function PromoCodeAfterRedemSuccess(state = PromoCodeAfterRedemSuccessState, action) {
  switch (action.type) {
    case 'PROMO_CODE_AFTER_REDEM_FULLFILED':
      return { ...state, loading: false, data: action.payload };
    case 'PROMO_CODE_AFTER_REDEM__NULL':
      return {
        ...state,
        loading: false,
        error: true,
        data: {},
      };
    default:
      return state;
  }
}
export function TransactionListPackageReducer(state = transactionListPackageState, action) {
  switch (action.type) {
    case 'TRANSACTION_LIST_PACKAGE_REDUCER_PENDING':
      return { ...state, loading: true };
    case 'TRANSACTION_LIST_PACKAGE_REDUCER_FULFILLED':
      return { ...state, loading: false, data: action.payload };
    case 'TRANSACTION_LIST_PACKAGE_REDUCER_REJECTED':
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}
export function TransactionPaymentReducer(state = transactionPaymentState, action) {
  switch (action.type) {
    case 'TRANSACTION_PAYMENT_REDUCER_PENDING':
      return { ...state, loading: true };
    case 'TRANSACTION_PAYMENT_REDUCER_FULFILLED':
      return { ...state, loading: false, data: action.payload };
    case 'TRANSACTION_PAYMENT_REDUCER_REJECTED':
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}
export function TransactionHistoryReducer(state = transactionHistoryState, action) {
  switch (action.type) {
    case 'TRANSACTION_HISTORY_REDUCER_PENDING':
      return { ...state, loading: true };
    case 'TRANSACTION_HISTORY_REDUCER_FULFILLED':
      return { ...state, loading: false, data: action.payload };
    case 'TRANSACTION_HISTORY_REDUCER_REJECTED':
      return { ...state, loading: false, error: true };
    case 'TRANSACTION_HISTORY_ITEM_REDUCER_PENDING':
      return { ...state, loading: true };
    case 'TRANSACTION_HISTORY_ITEM_REDUCER_FULFILLED':
      return { ...state, loading: false, data: action.payload };
    case 'TRANSACTION_HISTORY_ITEM_REDUCER_REJECTED':
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}
export function PaymentHistoryReducer(state = paymentHistoryState, action) {
  switch (action.type) {
    case 'PAYMENT_HISTORY_REDUCER_PENDING':
      return { ...state, loading: true };
    case 'PAYMENT_HISTORY_REDUCER_FULFILLED':
      return { ...state, loading: false, data: action.payload };
    case 'PAYMENT_HISTORY_REDUCER_REJECTED':
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}
export function CartTransactionReducer(state = cartTransactionState, action) {
  switch (action.type) {
    case 'CART_TRANSACTION_REDUCER_PENDING':
      return { ...state, loading: true };
    case 'CART_TRANSACTION_REDUCER_FULFILLED':
      return { ...state, loading: false, data: action.payload };
    case 'CART_TRANSACTION_REDUCER_REJECTED':
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}
export function PaymentConfirmationReducer(state = paymentConfirmationState, action) {
  switch (action.type) {
    case 'PAYMENT_CONFIRMATION_REDUCER_PENDING':
      return { ...state, loading: true };
    case 'PAYMENT_CONFIRMATION_REDUCER_FULFILLED':
      return { ...state, loading: false, data: action.payload };
    case 'PAYMENT_CONFIRMATION_REDUCER_REJECTED':
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}
