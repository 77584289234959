import AXIOS_BASE from '../../config';
import { PATH } from '../../variables';

const action = {};

action.dataPersonalLinkedin = function (sosmedId) {
  return AXIOS_BASE.LINKEDIN.get(
    `${PATH}/linkedin/general`
    + `?C_SOSMED_PERSONAL_ID=${sosmedId}`,
  );
};
action.dataSosmedUrlLinkedin = function (sosmedId) {
  return AXIOS_BASE.LINKEDIN.get(
    `${PATH}/linkedin/url`
    + `?C_SOSMED_PERSONAL_ID=${sosmedId}`,
  );
};
action.jobsLinkedin = function (sosmedId) {
  return AXIOS_BASE.LINKEDIN.get(
    `${PATH}/linkedin/jobs`
    + `?C_SOSMED_PERSONAL_ID=${sosmedId}`,
  );
};
action.schoolsLinkedin = function (sosmedId) {
  return AXIOS_BASE.LINKEDIN.get(
    `${PATH}/linkedin/schools`
    + `?C_SOSMED_PERSONAL_ID=${sosmedId}`,
  );
};
action.skillsLinkedin = function (sosmedId) {
  return AXIOS_BASE.LINKEDIN.get(
    `${PATH}/linkedin/skills`
    + `?C_SOSMED_PERSONAL_ID=${sosmedId}`,
  );
};
action.interestsLinkedin = function (sosmedId) {
  return AXIOS_BASE.LINKEDIN.get(
    `${PATH}/linkedin/interest`
    + `?C_SOSMED_PERSONAL_ID=${sosmedId}`,
  );
};
action.recomendationLinkedin = function (sosmedId) {
  return AXIOS_BASE.LINKEDIN.get(
    `${PATH}/linkedin/recommendation`
    + `?C_SOSMED_PERSONAL_ID=${sosmedId}`,
  );
};
action.certificationsLinkedin = function (sosmedId) {
  return AXIOS_BASE.LINKEDIN.get(
    `${PATH}/linkedin/certification`
    + `?C_SOSMED_PERSONAL_ID=${sosmedId}`,
  );
};
export default action;
