const promoState = {
  isLoading: false,
  error: false,
  promo: [],
  isBanner: false,
  msg: '',
  errMsg: false,
};

export default function PromoReducer(state = promoState, action) {
  switch (action.type) {
    case 'PROMO_REDUCER_PENDING':
      return { ...state, isLoading: true, error: false };
    case 'PROMO_REDUCER_FULFILLED':
      return {
        ...state, isLoading: false, error: false, promo: action.payload,
      };
    case 'SET_BANNER':
      return {
        ...state, isBanner: true,
      };
    case 'SET_BANNER_NULL':
      return {
        ...state, isBanner: false,
      };
    case 'PROMO_REDUCER_REJECTED':
      return { ...state, isLoading: false, error: true };
    case 'ERROR_APPLY_PROMO':
      return { ...state, msg: action.subError, errMsg: true };
    case 'ERROR_APPLY_PROMO_NULL':
      return { ...state, msg: '', errMsg: false };
    default:
      return state;
  }
}
