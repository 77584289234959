import AXIOS_BASE from '../../config';
import { PATH } from '../../variables';

const action = {};

action.clientInfo = function (token) {
  return AXIOS_BASE.BATCH.get(`${PATH}/client/info`, {
    headers: {
      Authorization: token,
    },
  });
};
action.postDetailProfile = function (body) {
  return AXIOS_BASE.BATCH.post(`${PATH}/client/detail-changes`, body);
};
action.detailProfile = function (token = false) {
  let config = {};
  if (config) {
    config = {
      headers: {
        Authorization: token,
      },
    };
  }
  return AXIOS_BASE.BATCH.get(`${PATH}/client/detail`, config);
};
action.listBatch = function (LIMIT) {
  return AXIOS_BASE.BATCH.get(`${PATH}/batch/list?SORT=new&LIMIT=${LIMIT}`);
};
action.checkAccess = function (batchId) {
  return AXIOS_BASE.BATCH.get(`${PATH}/batch/check-access?C_BATCH_ID=${batchId}`);
};
action.quotaCompany = function () {
  return AXIOS_BASE.BATCH.get(`${PATH}/batch/company/quota`);
};
action.quotaUsageCompany = function () {
  return AXIOS_BASE.BATCH.get(`${PATH}/batch/company/quota-usage`);
};
action.sosmedPeople = function (personid) {
  return AXIOS_BASE.BATCH.get(`${PATH}/batch/list/sosmed?C_PERSONAL_ID=${personid}`);
};
action.mostInfluentialBatch = function (personid, config = {}) {
  return AXIOS_BASE.BATCH.get(`${PATH}/batch/personal/sosmed?C_PERSONAL_ID=${personid}`, config);
};
action.mostInterestBatch = function (query, config = {}) {
  return AXIOS_BASE.BATCH.get(
    `${PATH}/batch/personal/interest?`
    + `${query}`,
    config,
  );
};
action.mostNegativityBatch = function (query, config) {
  return AXIOS_BASE.BATCH.get(
    `${PATH}/batch/personal/tendency?`
    + `${query}`,
    config,
  );
};
action.mostPoliticalPartyBatch = function (query, config) {
  return AXIOS_BASE.BATCH.get(
    `${PATH}/batch/personal/political-party?`
    + `${query}`,
    config,
  );
};
action.batchListPersonal = function (query) {
  return AXIOS_BASE.BATCH.get(
    `${PATH}/batch/personal`
    + `?${query}`,
  );
};
action.batchTopInteraksi = function (id) {
  return AXIOS_BASE.RESUME.get(
    `${PATH}/resume/personal/top-interaction?C_PERSONAL_ID=${id}`
    ,
  );
};
action.batchCountListPersonal = function (batchid) {
  return AXIOS_BASE.BATCH.get(
    `${PATH}/batch/`
    + `list-count?C_BATCH_ID=${batchid}`,
  );
};
action.createBatchManual = function (body) {
  return AXIOS_BASE.BATCH.post(`${PATH}/batch/add-form`, {
    V_BATCH_NAME: body.V_BATCH_NAME,
    D_START_DATE: body.D_START_DATE,
    D_END_DATE: body.D_END_DATE,
    V_PEOPLE_DATA: JSON.stringify(body.V_PEOPLE_DATA),
  });
};
action.editBatch = function (body) {
  return AXIOS_BASE.BATCH.post(`${PATH}/batch/update`, {
    C_BATCH_ID: body?.C_BATCH_ID,
    V_BATCH_NAME: body?.V_BATCH_NAME,
    D_START_DATE: body?.D_START_DATE,
    D_END_DATE: body?.D_END_DATE,
  });
};
action.updateBatchManual = function (body) {
  return AXIOS_BASE.BATCH.post(`${PATH}/batch/update-form`,
    {
      C_BATCH_ID: body.C_BATCH_ID,
      V_BATCH_NAME: body.V_BATCH_NAME,
      D_START_DATE: body.D_START_DATE,
      D_END_DATE: body.D_END_DATE,
      V_PEOPLE_DATA: JSON.stringify(body.V_PEOPLE_DATA),
    });
};
action.detailBatch = function (batchid) {
  return AXIOS_BASE.BATCH.get(`${PATH}/batch/person/update-temporary/existing/count?C_BATCH_ID=${batchid}`);
};
action.errorValidationUpdateTemporary = function (batchid) {
  return AXIOS_BASE.BATCH.get(`${PATH}/batch/person/update-temporary/list?C_BATCH_ID=${batchid}`);
};
action.errorValidationAddTemporary = function (batchid) {
  return AXIOS_BASE.BATCH.get(`${PATH}/batch/person/add-temporary/list?C_BATCH_ID=${batchid}`);
};
action.listEksistingData = function (batchid) {
  return AXIOS_BASE.BATCH.get(`${PATH}/batch/person/update-temporary/existing/list?C_BATCH_ID=${batchid}`);
};
action.addExcelCandidate = function (params) {
  const formData = new FormData();
  formData.append('FILES', new Blob([params.file], {
    type: 'application/vnd.ms-excel',
  }));
  formData.set('V_BATCH_NAME', params.batchName);
  formData.set('D_START_DATE', params.frmtDateStart);
  formData.set('D_END_DATE', params.frmtDateStop);
  if (params.exixtId !== undefined) formData.set('EXIST_BATCH_ID', params.exixtId);
  formData.set('NOT_REPLACE', params.notReplace || 'false');
  return AXIOS_BASE.BATCH.post(`${PATH}/batch/add-excel`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
action.updateExcelCandidate = function (params) {
  const formData = new FormData();
  formData.append('FILES', new Blob([params.file], {
    type: 'application/vnd.ms-excel',
  }));
  formData.set('V_BATCH_NAME', params.batchName);
  formData.set('D_START_DATE', params.frmtDateStart);
  formData.set('D_END_DATE', params.frmtDateStop);
  formData.set('C_BATCH_ID', params.batchid);
  if (params.exixtId !== undefined) formData.set('EXIST_BATCH_ID', params.exixtId);
  formData.set('NOT_REPLACE', params.notReplace || 'false');
  return AXIOS_BASE.BATCH.post(`${PATH}/batch/update-excel`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
action.historyCreateBatch = function (batchid) {
  return AXIOS_BASE.BATCH.get(`${PATH}/batch/history?C_BATCH_ID=${batchid}`);
};
action.updateCandidateForm = function (body) {
  return AXIOS_BASE.BATCH.post(
    `${PATH}/batch/person/update-temporary/add`,
    body,
  );
};
action.addCandidateForm = function (body) {
  return AXIOS_BASE.BATCH.post(
    `${PATH}/batch/person/add-temporary/add`,
    body,
  );
};
action.deleteCandidateUpdateValidation = function (body) {
  return AXIOS_BASE.BATCH.post(
    `${PATH}/batch/person/update-temporary/delete`,
    body,
  );
};
action.deleteCandidateAddValidation = function (body) {
  return AXIOS_BASE.BATCH.post(
    `${PATH}/batch/person/add-temporary/delete`,
    body,
  );
};
action.updateCandidateUpdateValidation = function (body) {
  return AXIOS_BASE.BATCH.post(
    `${PATH}/batch/person/update-temporary/update`,
    body,
  );
};
action.updateCandidateAddValidation = function (body) {
  return AXIOS_BASE.BATCH.post(
    `${PATH}/batch/person/add-temporary/update`,
    body,
  );
};
action.lockCandidateUpdateValidation = function (body) {
  return AXIOS_BASE.BATCH.post(
    `${PATH}/batch/person/update-temporary/lock`,
    body,
  );
};
action.lockCandidateAddValidation = function (body) {
  return AXIOS_BASE.BATCH.post(
    `${PATH}/batch/person/add-temporary/lock`,
    body,
  );
};
action.updateBatch = function (body) {
  return AXIOS_BASE.BATCH.post(
    `${PATH}/batch/update`,
    body,
  );
};
action.donwloadTemplateAddCandidate = function () {
  const localStorage = JSON.parse(window.localStorage['persist:root']);
  const status = JSON.parse(localStorage.StatusPageReducer);
  let url = `${PATH}/batch/excel-template`;
  if (process.env.NODE_ENV === 'development') {
    url = `http://${process.env.REACT_APP_URL_BATCH}${PATH}/batch/excel-template`;
  }
  return new Promise((resolve) => {
    fetch(url, {
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin',
      headers: {
        'user-agent': 'Mozilla/4.0 MDN Example',
        'content-type': 'application/vnd.ms-excel;charset=UTF-8',
        'Access-Control-Expose-Headers': 'Content-Disposition',
        Authorization: status.token,
      },
      method: 'GET', // *GET, POST, PUT, DELETE, etc.
    })
      .then((res) => res.blob().then((blob) => {
        let filename = decodeURI(res.headers.get('Content-Disposition').split('filename=')[1]);// 获取后端headers里面的文件名
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(blob, filename); // 兼容ie10
        } else {
          filename = filename.split('"').join('');
          const a = document.createElement('a');
          document.body.appendChild(a); // 兼容火狐，将a标签添加到body当中
          a.href = window.URL.createObjectURL(blob);
          a.download = `${filename}`;
          a.target = '_blank'; // a标签增加target属性
          a.click();
          a.remove();// 移除a标签
          window.URL.revokeObjectURL(url);
        }
        resolve();
      }));
  });
};
action.searchBar = function (name) {
  return AXIOS_BASE.BATCH.get(`${PATH}/batch/searchbar?V_KEY=${name}`);
};
action.listPromo = function () {
  return AXIOS_BASE.PAYMENT.get(`${PATH}/transaction/list-promo-code`);
};
action.redemPromo = function (body) {
  return AXIOS_BASE.PAYMENT.post(
    `${PATH}/transaction/redeem-promo-code`,
    {
      V_PROMO_CODE: body,
    },
  );
};
action.deleteBatchAtHomePage = function (C_BATCH_ID) {
  return AXIOS_BASE.BATCH.post(
    `${PATH}/batch/delete`,
    {
      C_BATCH_ID,
    },
  );
};
action.isSubmit = function (C_BATCH_ID, IS_SUCCESS_UPLOAD) {
  return AXIOS_BASE.BATCH.post(
    `${PATH}/batch/update-saved-condition`,
    {
      C_BATCH_ID,
      IS_SUCCESS_UPLOAD,
    },
  );
};
action.webHook = function (body) {
  const localStorage = JSON.parse(window.localStorage['persist:root']);
  const status = JSON.parse(localStorage.StatusPageReducer);
  return AXIOS_BASE.AUTH.post(`${PATH}/auth/webhook`, {
    V_WEBHOOK: body,
  }, {
    headers: {
      Authorization: status.token,
    },
  });
};
action.getWebHook = function () {
  const localStorage = JSON.parse(window.localStorage['persist:root']);
  const status = JSON.parse(localStorage.StatusPageReducer);
  return AXIOS_BASE.AUTH.get(`${PATH}/auth/webhook`, {
    headers: {
      Authorization: status.token,
    },
  });
};

export default action;
