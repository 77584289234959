/* eslint-disable import/no-mutable-exports */
import axios from 'axios';
import REDUX from '../redux/store';

const middlewareBeforeRequest = (config) => {
  const localStorage = JSON?.parse(window?.localStorage['persist:root']);
  const status = JSON?.parse(localStorage?.StatusPageReducer);

  let newConfig = { ...config };
  if (!config?.headers?.Authorization) {
    // eslint-disable-next-line no-unused-vars
    newConfig = {
      ...config,
      headers: {
        ...config?.headers,
        Authorization: status?.token,
      },
    };
  }
  return newConfig;
};
const middlewareRejectResponse = (error) => {
  if (error.response) {
    if (error.response.status === 401 || error.response.data.msg === 'invalid_token' || error.response.data.msg === 'jwt_malformed' || error.response.data.msg === 'jwt_expired') {
      REDUX.store.dispatch({
        type: 'STATUS_PAGE_REDUCER_RESET_ALL',
      });
    }
  }
  return Promise.reject(error);
};
let EX_AXIOS_BASE;
if (process.env.NODE_ENV === 'development') {
  const AXIOS_BASE = {};
  AXIOS_BASE.AUTH = axios.create({
    baseURL: `http://${process.env.REACT_APP_URL_AUTH}`,
  });
  AXIOS_BASE.BATCH = axios.create({
    baseURL: `http://${process.env.REACT_APP_URL_BATCH}`,
  });
  AXIOS_BASE.BATCH.interceptors.request.use(middlewareBeforeRequest);
  AXIOS_BASE.BATCH.interceptors.response.use(
    (res) => Promise.resolve(res), middlewareRejectResponse,
  );
  AXIOS_BASE.TWITTER = axios.create({
    baseURL: `http://${process.env.REACT_APP_URL_TWITTER}`,
  });
  AXIOS_BASE.TWITTER.interceptors.request.use(middlewareBeforeRequest);
  AXIOS_BASE.TWITTER.interceptors.response.use(
    (res) => Promise.resolve(res), middlewareRejectResponse,
  );
  AXIOS_BASE.RESUME = axios.create({
    baseURL: `http://${process.env.REACT_APP_URL_RESUME}`,
  });
  AXIOS_BASE.RESUME.interceptors.request.use(middlewareBeforeRequest);
  AXIOS_BASE.RESUME.interceptors.response.use(
    (res) => Promise.resolve(res), middlewareRejectResponse,
  );
  AXIOS_BASE.FACEBOOK = axios.create({
    baseURL: `http://${process.env.REACT_APP_URL_FACEBOOK}`,
  });
  AXIOS_BASE.FACEBOOK.interceptors.request.use(middlewareBeforeRequest);
  AXIOS_BASE.FACEBOOK.interceptors.response.use(
    (res) => Promise.resolve(res), middlewareRejectResponse,
  );
  AXIOS_BASE.INSTAGRAM = axios.create({
    baseURL: `http://${process.env.REACT_APP_URL_INSTAGRAM}`,
  });
  AXIOS_BASE.INSTAGRAM.interceptors.request.use(middlewareBeforeRequest);
  AXIOS_BASE.INSTAGRAM.interceptors.response.use(
    (res) => Promise.resolve(res), middlewareRejectResponse,
  );
  AXIOS_BASE.LINKEDIN = axios.create({
    baseURL: `http://${process.env.REACT_APP_URL_LINKEDIN}`,
  });
  AXIOS_BASE.LINKEDIN.interceptors.request.use(middlewareBeforeRequest);
  AXIOS_BASE.LINKEDIN.interceptors.response.use(
    (res) => Promise.resolve(res), middlewareRejectResponse,
  );
  AXIOS_BASE.MASTER = axios.create({
    baseURL: `http://${process.env.REACT_APP_URL_MASTER}`,
  });
  AXIOS_BASE.MASTER.interceptors.request.use(middlewareBeforeRequest);
  AXIOS_BASE.MASTER.interceptors.response.use(
    (res) => Promise.resolve(res), middlewareRejectResponse,
  );
  AXIOS_BASE.PAYMENT = axios.create({
    baseURL: `http://${process.env.REACT_APP_URL_PAYMENT}`,
  });
  AXIOS_BASE.PAYMENT.interceptors.request.use(middlewareBeforeRequest);
  AXIOS_BASE.PAYMENT.interceptors.response.use(
    (res) => Promise.resolve(res), middlewareRejectResponse,
  );

  AXIOS_BASE.NOTIF = axios.create({
    baseURL: `http://${process.env.REACT_APP_URL_NOTIF}`,
  });
  AXIOS_BASE.NOTIF.interceptors.request.use(middlewareBeforeRequest);
  AXIOS_BASE.NOTIF.interceptors.response.use(
    (res) => Promise.resolve(res), middlewareRejectResponse,
  );
  EX_AXIOS_BASE = AXIOS_BASE;
} else {
  axios.interceptors.request.use(middlewareBeforeRequest);
  axios.interceptors.response.use(
    (res) => Promise.resolve(res), middlewareRejectResponse,
  );
  EX_AXIOS_BASE = {
    AUTH: axios,
    BATCH: axios,
    TWITTER: axios,
    RESUME: axios,
    INSTAGRAM: axios,
    LINKEDIN: axios,
    FACEBOOK: axios,
    PAYMENT: axios,
    MASTER: axios,
    NOTIF: axios,
  };
}

export default EX_AXIOS_BASE;
